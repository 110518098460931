import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop'
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar'
import React from 'react'

function SnackAndBackDrop({responseAdd, responseUpdate, showBackDrop, setOpen, open}) {
  return (
    <>
      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
      {open && <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} setOpen1={setOpen} />}
    </>
  )
}

export default SnackAndBackDrop
