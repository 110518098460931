import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'

import { useGetAllRestaurantsQuery, BLUE_PRINTS, useGetRestaurantQuery, useDeleteRestaurantMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedRestaurant } from 'app/rkt_query/SelectedStuffReducer';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import ViewRestaurant from './ViewRestaurant';
import AddUpdateRestaurant from './AddUpdateRestaurant.js';


const RestaurantList = () => {

  const dispatch = useDispatch();
  //const params = useParams();

  const location = useLocation();

  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteRestaurant, responseDelete] = useDeleteRestaurantMutation();
  const { data: res_get_all, isLoading: isLoadingRestaurants, error: errorRestaurants } = useGetAllRestaurantsQuery();
  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);


  const bluePrint = BLUE_PRINTS.restaurant
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedRestaurant(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedRestaurant(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedRestaurant(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedRestaurant(row)); }
  bluePrint.handleDelete = (id) => { deleteRestaurant(id); }


  const navigate = useNavigate();

  const handleBack = () => {

    navigate("/categories", {
      // state: {
      //   relationalObject: res_get?.data?.learning_journey?.topic?.module?.course,
      //   learningJourneyId: location?.state?.relationalObject,
      //   module: res_get?.data?.module,
      // },
    });
  }

  // useEffect(() => {
  //     console.log(res_get_all_learning_journeys)
  // }, [res_get_all_learning_journeys])

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      <Snackbar responseAdd={responseDelete} />

      {/* <Button variant='contained' sx={{ width: '15%' }} onClick={handleBack} >Back</Button> */}

      <JumboDemoCard
        title="Shop"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="Shop"
            data={res_get_all?.data?.restaurants}
            bluePrint={bluePrint}
            ViewModel={() => <ViewRestaurant />}
            ModalContent={() => <AddUpdateRestaurant onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}
          />
          {/* <DataTable btnTitleAdd="product" data={res_get_all?.data?.products} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowRestaurant/> : <Restaurant onClose={hideDialog} />} /> */}
          {/* <DataTable heirarchyId={location?.state?.obj?.id} listData={res_get_all_learning_journeys?.data?.learning_journeys} ddTranslate={true} translate={true} relationalObject={location?.state?.relationalObject} btnTitleAdd="Restaurant" data={res_get_all?.data?.products} bluePrint={bluePrint} ViewModel={() => <ShowRestaurant />} ModalContent={() => <Restaurant onClose={hideDialog} />} /> */}
        </div>
      </JumboDemoCard>
    </>
  );
}


export default RestaurantList