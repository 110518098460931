import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab, Box } from "@mui/material";
import {
  selectedGallerySelector,
  setSelectedGallery,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddGalleryMutation,
  useGetGalleryQuery,
  useUpdateGalleryMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateGallery = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedGallery = useSelector(selectedGallerySelector);
  const {
    data: res_get,
    isLoading: isLoadingGallery,
    error: errorGallery,
  } = useGetGalleryQuery(selectedGallery?.id);

  const [updateGallery, responseUpdate] = useUpdateGalleryMutation();
  const [addGallery, responseAdd] = useAddGalleryMutation();
  const bluePrint = BLUE_PRINTS.gallery;

  const { fnShowSnackBar } = useSnackBarManager();

  const [disable, setDisabled] = useState([true]);

  const [galleryState, setGalleryState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  // const [error, setError] = useState([]);
  // const [count, setCount] = useState(true);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.gallery?.image
  );

  const addUpdateGalleryData = async (action) => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("id", res_get?.data?.gallery?.id);
    formData.append("name", galleryState.name);
    const res =
      action === "add"
        ? await addGallery(formData)
        : await updateGallery(formData);
    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message || "Something went wrong", true);
    }
  };

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    res_get?.data?.gallery?.image &&
      setSelectedFile(res_get?.data?.gallery?.image);

    // setCompanyId(res_get1?.data?.company?.id)
    setGalleryState(selectedGallery ? res_get?.data?.gallery : bluePrint);
    // setInputFields(modules)
    // setGalleryState(...courseState?.company_id && )
    setDisabled(selectedGallery ? false : true);

    // if (res_get1?.data?.company?.id) {
    //   //dispatch(setSelectedGallery(null));
    //   console.log("Checking")
    // }
  }, [res_get]);

  console.log("update response", galleryState);
  // const companies = res_get_all?.data?.companies?.map((company) => (company))
  // const callCompanyTeams = () => {
  //   setCompanyId(courseState?.company_id)
  // }
  // console.log(companies, "companies")
  // console.log('comp', companies)
  // console.log('transformed result :: ', res_get1?.data?.company?.name)
  // console.log('Disable :: ', disable)
  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {galleryState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* image start */}
          {imagePreview ? (
            <img
              src={imagePreview}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          ) : (
            <DZImageCircle
              image={selectedFile}
              link={Config.digitalOceanLinkGallerySmallImg}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>

          {/* 
          <DZTextValidation error={error?.[0]} label="Name" variant="outlined" value={galleryState?.name}
            onChange={(e) => { setGalleryState(x => ({ ...x, name: e.target.value })) }}
            placeholder="Name"

          /> */}

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedGallery(null));
              }}
            >
              Cancel
            </Button>
            {selectedGallery?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateGalleryData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateGalleryData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedGallery(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateGallery;
