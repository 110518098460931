import React from 'react';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ClearButton = ({ fieldName, handleClear }) => {
    return (
        <>
            {fieldName && (
                <IconButton onClick={() => handleClear(fieldName)} aria-label="clear">
                    <HighlightOffIcon />
                </IconButton>
            )}
        </>
    );
};

export default ClearButton;