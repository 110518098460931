import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField, Fab, Box } from '@mui/material';
import { selectedSubCategorySelector, setSelectedSubCategory, selectedCompanySelector, setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddSubCategoryMutation, useGetAllSubCategoriesQuery, useGetSubCategoryQuery, useUpdateSubCategoryMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown, { DZSimpleDropDown } from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField, { DZDateField } from '../../../@jumbo/dz-components/textboxes/DZTextField';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation, { MultiDZTextValidation } from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import { DzNoBackgroundTranslatable, DzNoBackgroundTranslatableModule } from '@jumbo/dz-components/labels/DZLabel';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
import SnackAndBackDrop from 'app/DZcomponents/SnackAndBackDrop';
import { useSnackBarManager } from 'app/hooks/useSnackBarManager';



const AddUpdateSubCategory = ({ onClose }) => {

  const dispatch = useDispatch();
  const selectedSubCategory = useSelector(selectedSubCategorySelector);
  const { data: res_get, isLoading: isLoadingSubCategory, error: errorSubCategory } = useGetSubCategoryQuery(selectedSubCategory?.id);
  const { data: res_get_all } = useGetAllSubCategoriesQuery();

  const [updateSubCategory, responseUpdate,] = useUpdateSubCategoryMutation();
  const [addSubCategory, responseAdd,] = useAddSubCategoryMutation();
  const bluePrint = BLUE_PRINTS.category;



  const { fnShowSnackBar } = useSnackBarManager();
  const [disable, setDisabled] = useState([true]);

  const [categoryState, setSubCategoryState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.category?.image);

  const addUpdateSubCategoryData = async (action) => {
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', res_get?.data?.category?.id);
    formData.append('name', JSON.stringify(categoryState.name));
    formData.append('parent_id', categoryState.parent_id ?? "");
    setOpen(true);
    const res = action === "add" ? await addSubCategory(formData) : await updateSubCategory(formData);
    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar("Something went wrong", true);
    }
  }

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  // if (responseAdd?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseAdd?.error?.data?.errors)
  // }

  // if (responseUpdate?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseUpdate?.error?.data?.errors)
  // }

  // const countZero = () => {
  //   setCount(true)
  // }
  // console.log(responseUpdate?.error?.data?.errors)
  // if (responseAdd?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseAdd?.error?.data?.errors)
  // }

  // if (responseUpdate?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseUpdate?.error?.data?.errors)
  // }



  useEffect(() => {

    res_get?.data?.category?.image && setSelectedFile(res_get?.data?.category?.image)


    // setCompanyId(res_get1?.data?.company?.id)
    setSubCategoryState(selectedSubCategory ? res_get?.data?.category : bluePrint);
    // setInputFields(modules)
    // setSubCategoryState(...courseState?.company_id && )
    setDisabled(selectedSubCategory ? false : true);

    // if (res_get1?.data?.company?.id) {
    //   //dispatch(setSelectedSubCategory(null));
    //   console.log("Checking")
    // }

  }, [res_get])

  console.log('update response', categoryState)
  // const companies = res_get_all?.data?.companies?.map((company) => (company))
  // const callCompanyTeams = () => {
  //   setCompanyId(courseState?.company_id)
  // }
  // console.log(companies, "companies")
  // console.log('comp', companies)
  // console.log('transformed result :: ', res_get1?.data?.company?.name)
  // console.log('Disable :: ', disable)
  // const filehandle = (e) => {
  //   if (e.target.files.length !== 0) {

  //     setSelectedFile(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(e.target.files[0]);

  //   }



  // };

  // const handleFormChange = (index, event) => {
  //   console.log('event: ', event.target.value);
  //   setInputFields(inputFields?.map((eng, i) => i === index ? {
  //     ...eng,
  //     [event.target.name]: (
  //       event.target.value)
  //   } : eng))
  // const values = [...inputFields];
  // values[index].value = event.target.value;
  // setInputFields(values);

  //console.log('event target value: ', event.target.value);
  //document.getElementsByName("reply_exercise_id")''
  // setInputFields(inputFields.map((eng, i) => i === index ? {
  //   ...eng,
  //   [event.target.name]: (
  //     event.target.value
  //       : { ...eng?.[event.target.name], [selectedLanguage]: event.target.value }
  // )
  // } : eng));

  // }
  // console.warn(inputFields);
  const categories = res_get_all?.data?.categories?.map((category) => (BLUE_PRINTS.subCategory.translate(category, selectedLanguage)));

  return (
    <div>

      <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />



      {categoryState ?
        <div style={addUpdateDivStyleGlobal}>

          {/* image start */}
          {/* {imagePreview ? (
            <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
          ) : (

            <DZImageCircle image={selectedFile} link={Config.digitalOceanLinkSubCategorySmallImg} style={{
              height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            />

          )} */}

          {/* <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
            <label htmlFor="upload-photo">
              <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>

            </label>
          </Box> */}
          <DZDropdown error={error?.parent_id} placeholder={'Select Category'} value={categoryState?.parent_id || ''} data={categories}
            onChange={(e) => { setSubCategoryState(x => ({ ...x, parent_id: e.target.value, })); }} />


          <MultiDZTextValidation error={error?.[0]} label="Name" variant="outlined" value={categoryState?.name[selectedLanguage]}
            onChange={(e) => { setSubCategoryState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })) }}
            placeholder="Name"

          />

          <div style={dialogButtonsStyle} >
            <Button variant="outlined" onClick={() => { onClose(); dispatch(setSelectedSubCategory(null)); }}>Cancel</Button>
            {
              selectedSubCategory?.id ? <Button variant="contained" onClick={() => { addUpdateSubCategoryData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                : <Button variant="contained" onClick={() => { addUpdateSubCategoryData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedSubCategory(null)); }}>Add</Button>
            }

          </div>

        </div>
        : <div>Loading....</div>
      }
   


    </div>
  )
}

export default AddUpdateSubCategory