import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";

import "./dawa-address.css";
import InputField from "./../InputField/InputField";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";

function DawaAutoAddress({ setAddress, setLong, setLat,value }) {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showAddress, setShowAddress] = useState(true);

  useEffect(() => {
    if (query.trim() === "") {
      setSuggestions([]);
      return;
    }
    axios
      .get(`https://dawa.aws.dk/adresser/autocomplete?q=${query}`)
      .then((response) => {
        const suggestions = response.data;
        setSuggestions(suggestions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [query]);

  const handleChange = (e) => {
    setQuery(e.target.value);
    setShowAddress(true);
  };

  const clickAddress = (suggestion) => {
    const address = {
      post_code: suggestion?.adresse?.postnr,
      city: suggestion?.adresse?.postnrnavn,
      lat: suggestion?.adresse?.y,
      long: suggestion?.adresse?.x,
      street: suggestion?.tekst,
    };
    setAddress(address.street);
    setLat(address.lat);
    setLong(address.long);
    setQuery(suggestion?.tekst);
    setShowAddress(false);
  };
  return (
    <div className="address__search">
      <DZTextValidation
      label="Address"
        col={12}
        sx={12}
        sx3mb={0.5}
        sx3mt={1}
        sx3ml={2}
        placeholder="Search Your Address Here"
        value={query?query:value}
        onChange={handleChange}
      />

      {/* <InputField id="outlined-basic" label="Outlined" variant="outlined"
        placeholder="Search for addresses"
        value={query}
        onChange={handleChange}
        className='address__input'
      />
  */}
      {suggestions.length > 0 && showAddress && (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {suggestions.map((suggestion, index) => (
            <ListItemButton onClick={() => clickAddress(suggestion)}>
              <ListItemText key={index} primary={suggestion.tekst} />
            </ListItemButton>
          ))}
        </List>
      )}
    </div>
  );
}

export default DawaAutoAddress;
