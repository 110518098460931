import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'

import {  BLUE_PRINTS, useGetOrderQuery, useGetAllOrdersQuery, useDeleteOrderMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { setSelectedOrder } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import ViewOrderDetail from './ViewOrderDetail';
import UpdateOrderStatus from './UpdateOrderStatus.js';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';


const Orders = () => {
  const { data: res_get_all, isLoading: isLoadingOrder, error: errorOrder } = useGetAllOrdersQuery();
  const dispatch = useDispatch();
 


  const [deleteOrder, responseDelete] = useDeleteOrderMutation();
 
 


  const [showMode, setShowMode] = useState(false);



  const bluePrint = BLUE_PRINTS.order
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedOrder(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedOrder(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedOrder(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedOrder(row)); }
  bluePrint.handleDelete = (id) => { deleteOrder(id); }




 


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

      {/* <Button variant='contained' sx={{ width: '15%' }} onClick={handleBack} >Back</Button> */}

      <JumboDemoCard
        title="Orders"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable
            btnTitleAdd="Orders"
            data={res_get_all?.data?.orders}
            bluePrint={bluePrint}
            ViewModel={() => <ViewOrderDetail />}
            // ModalContent={() => <UpdateOrderStatus onClose={hideDialog} fullscreen={true} />}
            showAddButton={false}
            viewTitle2="Order Status"
            addButtonHeader={false}
            hideEditBtn={false}
          />
       
        </div>
      </JumboDemoCard>
    </>
  );
}


export default Orders