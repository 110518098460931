import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab, Box } from "@mui/material";
import {
  selectedDeliveryPostCodeSelector,
  setSelectedDeliveryPostCode,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddDeliveryPostCodeMutation,
  useGetDeliveryPostCodeQuery,
  useUpdateDeliveryPostCodeMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateDeliveryPostCode = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedDeliveryPostCode = useSelector(
    selectedDeliveryPostCodeSelector
  );
  const {
    data: res_get,
    isLoading: isLoadingDeliveryPostCode,
    error: errorDeliveryPostCode,
  } = useGetDeliveryPostCodeQuery(selectedDeliveryPostCode?.id);
  const { fnShowSnackBar } = useSnackBarManager();
  const [updateDeliveryPostCode, responseUpdate] =
    useUpdateDeliveryPostCodeMutation();
  const [addDeliveryPostCode, responseAdd] = useAddDeliveryPostCodeMutation();
  const bluePrint = BLUE_PRINTS.deliveryPostCode;
  const [deliveryPostCodeState, setDeliveryPostCodeState] = useState(null);
  const [open, setOpen] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);

  const addUpdateDeliveryPostCodeData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.post_code?.id);
    formData.append("postcode", deliveryPostCodeState.postcode);
    formData.append("price", deliveryPostCodeState.price);
    setOpen(true);
    const res =
      action === "add"
        ? await addDeliveryPostCode(formData)
        : await updateDeliveryPostCode(formData);
    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar("Something went wrong", true);
    }
  };

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setDeliveryPostCodeState(
      selectedDeliveryPostCode ? res_get?.data?.post_code : bluePrint
    );
  }, [res_get]);

  return (
    <div>
      <SnackAndBackDrop
        showBackDrop={showBackDrop}
        responseAdd={responseAdd}
        responseUpdate={responseUpdate}
        setOpen={setOpen}
        open={open}
      />

      {deliveryPostCodeState ? (
        <div style={addUpdateDivStyleGlobal}>
          <DZTextValidation
            error={error?.[0]}
            label="Post Code"
            variant="outlined"
            value={deliveryPostCodeState?.postcode}
            onChange={(e) => {
              setDeliveryPostCodeState((x) => ({
                ...x,
                postcode: e.target.value,
              }));
            }}
            placeholder="Post Code"
          />
          <Grid item xs={12} md={6} lg={6}>
            <Div sx={{ mr: 2 }}>
              <TextField
                fullWidth
                label="Delivery Min Price"
                type="number"
                error={error?.price}
                variant="outlined"
                noOfRows={1}
                value={deliveryPostCodeState?.price}
                onChange={(e) => {
                  setDeliveryPostCodeState((x) => ({
                    ...x,
                    price: e.target.value,
                  }));
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Div>
          </Grid>

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedDeliveryPostCode(null));
              }}
            >
              Cancel
            </Button>
            {selectedDeliveryPostCode?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateDeliveryPostCodeData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateDeliveryPostCodeData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedDeliveryPostCode(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateDeliveryPostCode;
