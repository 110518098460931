import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllUsersQuery, BLUE_PRINTS, useGetUserQuery, useDeleteUserMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedUser } from 'app/rkt_query/SelectedStuffReducer';
import ViewUser from './ViewUser';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import User from './AddUpdateUser';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import AddUpdateUser from './AddUpdateUser';

const UserList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteUser, responseDelete] = useDeleteUserMutation();
  const { data: res_get_all, isLoading: isLoadingUsers, error: errorUsers } = useGetAllUsersQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.user
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedUser(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedUser(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedUser(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedUser(row)); }
  bluePrint.handleDelete = (id) => { deleteUser(id); }

  // const reloadPage = () => {
  //    window.location.reload()
  // }

  //if (responseDelete?.isSuccess) { reloadPage() }


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"Users"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="User" data={res_get_all?.data?.users} bluePrint={bluePrint} ViewModel={() => <ViewUser />} ModalContent={() => <AddUpdateUser onClose={hideDialog} />} />
          {/* <DataTable btnTitleAdd="user" data={res_get_all?.data?.users} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowUser/> : <User onClose={hideDialog} />} /> */}
        </div>
      </JumboDemoCard>
    </>
  )
}


export default UserList