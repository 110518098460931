import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedSubCategorySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetSubCategoryQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewSubCategory = ({ selectId }) => {

    const selectedSubCategory = useSelector(selectedSubCategorySelector);
    const [SubCategoryId, setSubCategoryId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingSubCategory, error: errorSubCategory } = useGetSubCategoryQuery(SubCategoryId);
    // const ViewSubCategory = ({showId}) => {
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.category?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        res_get?.data?.category?.image && setSelectedFile(res_get?.data?.category?.image)
        setSubCategoryId(selectedSubCategory ? selectedSubCategory?.id : selectId)
    }, [])
    const categoryArray =
        [
            { label: 'Name', data: res_get?.data?.category?.name[selectedLanguage] },
            { label: 'Parent Category', data: res_get?.data?.sub_category?.name[selectedLanguage] },

        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorSubCategory ? <div>{JSON.stringify(errorSubCategory)}</div>
                : isLoadingSubCategory ? <div>Loading Single SubCategory.....</div>
                    : res_get?.data?.category ?
                        <DZGenericView obj={categoryArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewSubCategory

