import React, { useState } from 'react'
import { TextField } from '@mui/material';
// import InputAdornment from '@mui/material/InputAdornment';
import TranslateIcon from '@mui/icons-material/Translate';
import InputAdornment from '@mui/material/InputAdornment';
import Div from '@jumbo/shared/Div/Div';
import Grid from "@mui/material/Grid"; 
const DZTextValidation = ({ sx3mb= "", sx3mt= "", sx3mr= "", sx3ml ="", col = 12, sx2 = 12,placeholder='',  error, type = 'text', sx = '', errorValue = '', label = '', noOfRows = 1, name = '', variant = "outlined", value = "", onChange = (e) => { },readOnly = false }) => {

  return ( 
    <Grid item xs={sx2} md={col} lg={col}>
      <Div sx={{ mb:sx3mb, mt:sx3mt, mr:sx3mr, ml:sx3ml }}>
        <TextField fullWidth id="outlined-basic" sx={sx} placeholder={placeholder}  inputProps={{ type: 'number', readOnly: readOnly }}  {...(error && { error: true, helperText: error })} label={label} variant={variant} value={value} onChange={onChange} multiline maxRows={3} minRows={noOfRows}   />
      </Div> 
    </Grid>
  )
}

export default DZTextValidation


export const MultiDZTextValidation = ({ col = 12, sx2 = 12, error, type = 'text', sx = '', errorValue = '', label = '', noOfRows = 1, name = '', variant = "outlined", value = "", onChange = (e) => { } }) => {

  return (
    <Grid item xs={sx2} md={col} lg={col}>
      <Div sx={{ mb: 0.5, mt: 1 }}>
        <TextField
          fullWidth id="outlined-basic"
          sx={sx}
          inputProps={{ type: 'number' }} {...(error && { error: true, helperText: error })}
          label={label}
          variant={variant}
          value={value}
          onChange={onChange}
          multiline maxRows={3}
          minRows={noOfRows}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <TranslateIcon />
              </InputAdornment>
            ),
          }}
        />
      </Div>
    </Grid>

    //  error={redErr} helperText={redErr? 'Required*' : ''}

  )
}



export const DZTextPasswordValidation = ({ error, errorValue = '', label = '', noOfRows = 1, variant = "outlined", value = "", onChange = (e) => { } }) => {

  return (
    <TextField id="outlined-basic"  {...(error && { error: true, helperText: error })} label={label} variant={variant} value={value} onChange={onChange} multiline maxRows={3} minRows={noOfRows} />
    //  error={redErr} helperText={redErr? 'Required*' : ''}
  )
}