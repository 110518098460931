import {
  hideSnackBarMessage,
  setSnackBarMessage,
  setSnackBarStatus,
  setSnackBarStatusNull,
  snackBarMessageSelector,
  snackBarStatusSelector,
} from "app/rkt_query/SnackBarReducer";
import { useDispatch, useSelector } from "react-redux";

export function useSnackBarManager() { 
  const dispatch = useDispatch();

  const isShowSnackBar = useSelector(snackBarMessageSelector);
  const isErrorTrue = useSelector(snackBarStatusSelector);

  const fnShowSnackBar = (message, isError = false, duration = 3000) => {
    if (message) {
      dispatch(setSnackBarMessage(message));
      dispatch(setSnackBarStatus(isError));
      setTimeout(() => {
        dispatch(hideSnackBarMessage());
        dispatch(setSnackBarStatusNull());
      }, duration);
    }
  };

  const fnHideSnackBar = () => {
    dispatch(hideSnackBarMessage());
    dispatch(setSnackBarStatusNull());
  };

  return {
    isShowSnackBar,
    fnShowSnackBar,
    fnHideSnackBar,
    isErrorTrue,
  };
}
