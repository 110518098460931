import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetProductAccessoryQuery, useDeleteProductAccessoryMutation, useGetAllProductAccessoriesQuery, BLUE_PRINTS } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { reload } from 'firebase/auth';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
//import Course from '../course/AddUpdateCourse';
import { setSelectedProductAccessory } from 'app/rkt_query/SelectedStuffReducer';
import AddProductAccessory from './AddProductAccessory';
import Paper from '@mui/material/Paper';
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableBody } from '@mui/material';
import { DZText } from 'app/DZcomponents/DZText';
import TableContainer from '@mui/material/TableContainer';
import { Box, IconButton, Button, Typography } from '@mui/material';
// import ViewCourse from '../course/ViewCourse';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { AddOutlined, ControlPointDuplicateRounded, PhotoCamera } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import Ordering from '@jumbo/dz-components/drag-drop-ordering/Ordering';



const ProductAccessoriesList = () => {

    const location = useLocation();
    const navigate = useNavigate();

    console.log("from course", location?.state?.relationalObject);

    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteProductAccessory, responseDelete] = useDeleteProductAccessoryMutation();
    const { data: res_get_all, isLoading: isLoadingProductAccessory, error: errorProductAccessory } = useGetAllProductAccessoriesQuery(location?.state?.relationalObject?.id);

    // const { data: res_get_all } = useGetProductAccessoryQuery(location?.state?.relationalObject?.id);
    // const { data: res_get_all } = useGetProductAccessoryQuery(location?.state?.relationalObject?.id);

    // console.log('useGetAllProductAccessoriesQuery: ', res_get_all);
    const [showMode, setShowMode] = useState(false);

    const handleHideDialog = () => {
        hideDialog();

    }
   
    const bluePrint = BLUE_PRINTS.productAccessory
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedProductAccessory(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedProductAccessory(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedProductAccessory(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedProductAccessory(row)); }
    bluePrint.handleDelete = (id) => { deleteProductAccessory(id); }
    const handleProductAccessoryAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
    const size = "xl";
    const showAddDialog = React.useCallback(() => {
        showDialog({
            title: "Add Product Accessory",
            size,
            content: <AddProductAccessory selectedId={location?.state?.relationalObject?.id} onClose={() => handleHideDialog()} onSave={handleProductAccessoryAdd} />
        });
    }, [showDialog]);
    // const handleCourseView = () => {
    //     showDialog({
    //         title: 'Course Detail',
    //         size,
    //         content: <ViewCourse selectId={res_get_all?.data?.course?.id} />
    //     });
    // }
    // const handleBack = () => {

    //     navigate("/teams", {
    //         state: {
    //             company_id: location.state.company_id,
    //         },
    //     });
    // }
    // console.log(res_get_all?.data?.user_courses[0]?.course?.users)


    return (
        <>
            {/* <StoreTokenCheck /> */}
            {/* <span>{showMode}</span> */}
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

            <TableContainer component={Paper} >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <h2 style={{ marginLeft: '12px', fontWeight: 'normal' }}>Product Accessory</h2>
                        <TableRow>
                            <TableCell align="left">Product</TableCell>
                            {/* <TableCell align="left">Company</TableCell> */}
                            {/* <TableCell align="center">Add Product Accessory</TableCell> */}
                            {/* <TableCell align="right">View Course</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {


                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell component="th" scope="row">
                                    <DZText>{location?.state?.relationalObject?.title}</DZText>
                                </TableCell>
                                {/* <TableCell component="th" scope="row">
                                    <DZText>{res_get_all?.data?.course?.company?.name}</DZText>
                                </TableCell> */}
                                {/* <TableCell component="th" scope="row">
                                    <div onClick={() => handleCourseView()} >
                                        <DZText>{res_get_all?.data?.course?.company?.name}</DZText>
                                        <IconButton
                                            onClick={() => handleCourseView()}
                                            aria-label="course" component="label"
                                        >
                                            <VisibilityOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </TableCell> */}

                                {/* <TableCell align="center">
                                    <IconButton
                                        onClick={() => showAddDialog()}
                                        aria-label="add team users" component="label"
                                    >
                                        <AddOutlined />
                                    </IconButton>
                                </TableCell> */}
                                {/* <TableCell align="right">
                                    {
                                        <IconButton onClick={() => handleCourseView()}>
                                            <VisibilityOutlinedIcon onClick={() => handleCourseView()} />
                                        </IconButton>
                                    }
                                </TableCell> */}
                            </TableRow>

                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {<SimpleButtons title={"Product Accessory Ordering"} onClick={() =>
       showDialog({
          title: 'Product Accessory List',
          size,
          content: <Ordering dataList={res_get_all?.data?.product_accessories} table={"product_accessory"} />
        })
      } />}
            <JumboDemoCard
                title="Product Accessory"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        //showAutoComplete={true}
                        btnTitleAdd="Product Accessory"
                        //relationalObject={location?.state?.relationalObject}
                        // listData={res_get_all_companies?.data?.companies}
                        // companyName={location?.state?.company}
                        // translate={true}
                        // ddTranslate={false}
                        data={res_get_all?.data?.product_accessories}
                        bluePrint={bluePrint}
                        //ViewModel={() => <ViewCourse />}
                        ModalContent={() => <AddProductAccessory onClose={hideDialog} selectedId={ location?.state?.relationalObject?.id} />}
                        // heirarchyId={location?.state?.obj?.id}
                        // RelationalModalContent={() => <Module onClose={hideDialog} />}
                        // relationalTitle="Module"
                        // ddName="Companies"
                        showAddButton={true}
                    />

                </div>
            </JumboDemoCard>
        </>
    )
}

export default ProductAccessoriesList