import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab } from "@mui/material";
import {
  selectedRestaurantSelector,
  setSelectedRestaurant,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddRestaurantMutation,
  useGetCurrentRestaurantQuery,
  useUpdateRestaurantMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZTextField from "./../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "./../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@mui/material/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  List,
  ListItem,
  Avatar,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import DawaAutoAddress from "app/DZcomponents/dawa-address/DawaAutoAddress";
import DZDefaultImg from "app/DZcomponents/DZDefaultImg";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const UpdateViewRestaurantInfo = () => {
  const [value, setValue] = React.useState("1");
  const [open, setOpen] = React.useState(false);
  const { data: res_get } = useGetCurrentRestaurantQuery();
  const [updateRestaurant, responseUpdate] = useUpdateRestaurantMutation();
  const [restaurantState, setRestaurantState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const [count, setCount] = useState(true);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.restaurant?.image
  );
  const [loading, setLoading] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const countZero = () => {
    setCount(true);
  };
  if (responseUpdate?.error?.data?.errors && count) {
    setCount(false);
    setError(responseUpdate?.error?.data?.errors);
  }
  useEffect(() => {
    res_get?.data?.restaurant?.image &&
      setSelectedFile(res_get?.data?.restaurant?.image);

    setRestaurantState({
      ...res_get?.data?.restaurant,
      linkedin: res_get?.data?.restaurant?.restaurant_link?.linkedin,
      youtube: res_get?.data?.restaurant?.restaurant_link?.youtube,
      twitter: res_get?.data?.restaurant?.restaurant_link?.twitter,
      facebook: res_get?.data?.restaurant?.restaurant_link?.facebook,
      instagram: res_get?.data?.restaurant?.restaurant_link?.instagram,
      tiktok: res_get?.data?.restaurant?.restaurant_link?.tiktok,
    });
  }, [res_get]);

  const addUpdateRestaurantData = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("id", res_get?.data?.restaurant?.id);
    formData.append("title", restaurantState.title);
    formData.append("pickup_time", restaurantState.pickup_time);
    formData.append("delivery_time", restaurantState.delivery_time);
    formData.append("delivery_min_price", restaurantState.delivery_min_price);
    formData.append("web_discount", restaurantState.web_discount);
    formData.append("app_discount", restaurantState.app_discount);
    formData.append("posc_discount", restaurantState.posc_discount);
    formData.append("delivery_distance", restaurantState.delivery_distance);
    formData.append("phone", restaurantState.phone);
    formData.append("health_care_link", restaurantState.health_care_link);
    formData.append("email", restaurantState.email);
    formData.append("lat", restaurantState.lat);
    formData.append("long", restaurantState.long);
    formData.append("address", restaurantState.address);
    formData.append("stars", restaurantState.stars);
    formData.append("facebook", restaurantState.facebook);
    formData.append("twitter", restaurantState.twitter);
    formData.append("linkedin", restaurantState.linkedin);
    formData.append("instagram", restaurantState.instagram);
    formData.append("tiktok", restaurantState.tiktok);
    formData.append("youtube", restaurantState.youtube);
    formData.append("detail", JSON.stringify(restaurantState.detail));
    updateRestaurant(formData)
      .unwrap()
      .then((payload) => {
        if(payload?.data?.message){
          fnShowSnackBar(payload?.data?.message || "Done successfully");
        } else {
          fnShowSnackBar("Something went wrong", true);
        }
        setLoading(false);
        setOpen(true);
      }).catch((error) => {
        setLoading(false);
        fnShowSnackBar("Something went wrong", true);
      });
  };
  const filehandle = (e) => {
    console.log("file handle image", e.target.files[0]);
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      console.log("Checking", selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };
  return (
    <>
      <div>
        {showBackDrop && <SimpleBackdrop responseUpdate={responseUpdate} />}
        <Snackbar
          sx={{ mt: 8 }}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleToClose}
        >
          <Alert
            onClose={handleToClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {responseUpdate?.data?.message}
          </Alert>
        </Snackbar>

        <JumboDemoCard
          title={"Shop Info"}
          wrapperSx={{
            p: 0,
            backgroundColor: "background.paper",
            "&:last-child": { pb: 0 },
          }}
        >
          <div
            style={{
              marginTop: 25,
              display: "flex",
              flexDirection: "column",
              gap: 25,
              marginBottom: 60,
            }}
          >
            <DZDefaultImg src={imagePreview} selectedFile={selectedFile} />


            <Box
              sx={{
                display: "flex",
                paddingBottom: "0px",
                justifyContent: "center",
                paddingTop: "0px",
              }}
            >
              <label htmlFor="upload-photo">
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={filehandle}
                />
                <Fab
                  color="primary"
                  size="small"
                  component="span"
                  aria-label="add"
                  variant="extended"
                >
                  Upload photo
                </Fab>
              </label>
            </Box>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Shop Info" value="1" />
                    <Tab label="Shop Social Links" value="2" />
                  </TabList>

                  <Box sx={{ flex: 1 }}>
                    <TabPanel value="1" sx={{ marginLeft: 0 }}>
                      <Grid container spacing={3.75}>
                        {/* <Grid item xs={12} md={8} lg={8}>

                    <DzNoBackgroundTranslatable title={"Learning Journey"} children={res_get1?.data?.learning_journey?.name} />

                </Grid> */}

                        <DZTextValidation
                          col={6}
                          sx={12}
                          sx3mb={0.5}
                          sx3mt={1}
                          sx3ml={2}
                          error={error?.title}
                          label="Title"
                          variant="outlined"
                          value={restaurantState?.title}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              title: e.target.value,
                            }));
                          }}
                        />
                        <DZTextValidation
                          sx={12}
                          col={6}
                          sx3mb={0.5}
                          sx3mt={1}
                          sx3mr={2}
                          error={error?.email}
                          label="Email"
                          variant="outlined"
                          value={restaurantState?.email}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              email: e.target.value,
                            }));
                          }}
                        />

                        <DZTextValidation
                          sx={12}
                          col={6}
                          sx3ml={2}
                          fullWidth
                          label="Phone"
                          type="number"
                          inputProps={{ min: 1 }}
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.phone}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              phone: e.target.value,
                            }));
                          }}
                        />

                        <DZTextValidation
                          sx={12}
                          col={6}
                          sx3mr={2}
                          error={error?.pickup_time}
                          label="Pickup Time"
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.pickup_time}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              pickup_time: e.target.value,
                            }));
                          }}
                        />

                        <DZTextField
                          sx={12}
                          col={6}
                          sx3ml={2}
                          label="Delivery Time"
                          error={error?.delivery_time}
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.delivery_time}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              delivery_time: e.target.value,
                            }));
                          }}
                        />

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mr: 2 }}>
                            <TextField
                              fullWidth
                              label="Delivery Min Price"
                              type="number"
                              error={error?.delivery_min_price}
                              variant="outlined"
                              noOfRows={1}
                              value={restaurantState?.delivery_min_price}
                              onChange={(e) => {
                                setRestaurantState((x) => ({
                                  ...x,
                                  delivery_min_price: e.target.value,
                                }));
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ ml: 2 }}>
                            <TextField
                              fullWidth
                              label="Web Discount (%)"
                              type="number"
                              error={error?.web_discount}
                              variant="outlined"
                              noOfRows={1}
                              value={restaurantState?.web_discount}
                              onChange={(e) => {
                                setRestaurantState((x) => ({
                                  ...x,
                                  web_discount: e.target.value,
                                }));
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mr: 2 }}>
                            <TextField
                              fullWidth
                              label="App Discount (%)"
                              type="number"
                              error={error?.app_discount}
                              variant="outlined"
                              noOfRows={1}
                              value={restaurantState?.app_discount}
                              onChange={(e) => {
                                setRestaurantState((x) => ({
                                  ...x,
                                  app_discount: e.target.value,
                                }));
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ ml: 2 }}>
                            <TextField
                              fullWidth
                              label="POSC Discount (%)"
                              type="number"
                              error={error?.posc_discount}
                              variant="outlined"
                              noOfRows={1}
                              value={restaurantState?.posc_discount}
                              onChange={(e) => {
                                setRestaurantState((x) => ({
                                  ...x,
                                  posc_discount: e.target.value,
                                }));
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mr: 2 }}>
                            <TextField
                              fullWidth
                              label="Delivery Distance"
                              type="number"
                              variant="outlined"
                              noOfRows={1}
                              value={restaurantState?.delivery_distance}
                              onChange={(e) => {
                                setRestaurantState((x) => ({
                                  ...x,
                                  delivery_distance: e.target.value,
                                }));
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ ml: 2 }}>
                            <TextField
                              fullWidth
                              label="Stars"
                              type="number"
                              variant="outlined"
                              noOfRows={1}
                              value={restaurantState?.stars}
                              onChange={(e) => {
                                setRestaurantState((x) => ({
                                  ...x,
                                  stars: e.target.value,
                                }));
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Div>
                        </Grid>
                        <DZTextField
                          sx={12}
                          col={6}
                          sx3mt={0}
                          label=" Danish Veterinary and Food Administration Link"
                          error={error?.health_care_link}
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.health_care_link}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              health_care_link: e.target.value,
                            }));
                          }}
                        />

                        {/* <DZTextField
                          sx={12}
                          col={6}
                          sx3ml={2}
                          label="Lat"
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.lat}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              lat: e.target.value,
                            }));
                          }}
                        />

                        <DZTextField
                          sx={12}
                          col={6}
                          sx3mr={2}
                          label="Long"
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.long}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              long: e.target.value,
                            }));
                          }}
                        /> */}
                        <Grid item xs={12} md={12} lg={12}>
                          <Div sx={{ ml: 2 }}>
                            <DawaAutoAddress
                              value={restaurantState?.address}
                              setAddress={(address) =>
                                setRestaurantState((prevState) => ({
                                  ...prevState,
                                  address: address,
                                }))
                              }
                              setLat={(lat) =>
                                setRestaurantState((prevState) => ({
                                  ...prevState,
                                  lat: lat,
                                }))
                              }
                              setLong={(long) =>
                                setRestaurantState((prevState) => ({
                                  ...prevState,
                                  long: long,
                                }))
                              }
                            />
                          </Div>
                        </Grid>
                        {/* <DZTextField
                          sx={12}
                          col={12}
                          sx3ml={2}
                          sx3mr={2}
                          label="Address"
                          variant="outlined"
                          error={error?.address}
                          noOfRows={3}
                          value={restaurantState?.address}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              address: e.target.value,
                            }));
                          }}
                        /> */}

                        <DZTextField
                          sx={12}
                          col={12}
                          sx3ml={2}
                          sx3mr={2}
                          label="Detail"
                          variant="outlined"
                          noOfRows={3}
                          maxRows={5}
                          value={restaurantState?.detail?.[selectedLanguage]}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              detail: {
                                ...x.detail,
                                [selectedLanguage]: e.target.value,
                              },
                            }));
                          }}
                        />
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2" sx={{ marginLeft: 0 }}>
                      <Grid container spacing={3.75}>
                        {/* <Grid item xs={12} md={8} lg={8}>

                    <DzNoBackgroundTranslatable title={"Learning Journey"} children={res_get1?.data?.learning_journey?.name} />

                </Grid> */}
                        {/* social link */}
                        <DZTextValidation
                          col={6}
                          sx={12}
                          sx3mb={0.5}
                          sx3mt={1}
                          sx3ml={2}
                          error={error?.facebook}
                          label="Facebook"
                          variant="outlined"
                          value={restaurantState?.facebook}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              facebook: e.target.value,
                            }));
                          }}
                        />
                        <DZTextValidation
                          sx={12}
                          col={6}
                          sx3mb={0.5}
                          sx3mt={1}
                          sx3mr={2}
                          error={error?.instagram}
                          label="Instagram"
                          variant="outlined"
                          value={restaurantState?.instagram}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              instagram: e.target.value,
                            }));
                          }}
                        />

                        <DZTextValidation
                          sx={12}
                          col={6}
                          sx3ml={2}
                          fullWidth
                          label="Twitter"
                          type="number"
                          inputProps={{ min: 1 }}
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.twitter}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              twitter: e.target.value,
                            }));
                          }}
                        />

                        <DZTextValidation
                          sx={12}
                          col={6}
                          sx3mr={2}
                          error={error?.LinkedIn}
                          label="LinkedIn"
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.linkedin}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              LinkedIn: e.target.value,
                            }));
                          }}
                        />

                        <DZTextField
                          sx={12}
                          col={6}
                          sx3ml={2}
                          label="Youtube"
                          error={error?.youtube}
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.youtube}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              youtube: e.target.value,
                            }));
                          }}
                        />
                        <DZTextField
                          sx={12}
                          col={6}
                          sx3mr={2}
                          label="TikTok"
                          error={error?.tiktok}
                          variant="outlined"
                          noOfRows={1}
                          value={restaurantState?.tiktok}
                          onChange={(e) => {
                            setRestaurantState((x) => ({
                              ...x,
                              tiktok: e.target.value,
                            }));
                          }}
                        />
                      </Grid>
                    </TabPanel>
                  </Box>
                </Box>
              </TabContext>
            </Box>

            {/* <Grid item xs={12} md={6} lg={6}> */}

            {/* </Grid> */}

            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(0.5, 3) }}
            >
              <ListItemIcon
                sx={{ minWidth: 36, color: "text.secondary" }}
              ></ListItemIcon>
              <ListItemText
                secondary={
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={() => {
                      addUpdateRestaurantData();
                      setShowBackDrop(true);
                      countZero();
                    }}
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                }
              />
            </ListItem>
          </div>
        </JumboDemoCard>
      </div>
    </>
  );
};

export default UpdateViewRestaurantInfo;
