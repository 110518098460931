import React, { useState, useEffect } from "react";
import SnackbarAlert from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { Portal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  hideSnackBarMessage,
  setSnackBarStatusNull,
  snackBarMessageSelector,
  snackBarStatusSelector,
} from "app/rkt_query/SnackBarReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Snackbar = ({ responseAdd }) => {
  const [open, setOpen] = useState(true);
  const { hideDialog } = useJumboDialog();
  useEffect(() => {
    handleClick();
    //console.log("click")
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    hideDialog();
    setOpen(false);
  };
  console.log(responseAdd);
  return (
    <>
      {responseAdd?.isSuccess && (
        <SnackbarAlert
          autoHideDuration={1500}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {responseAdd?.data?.message}
          </Alert>
        </SnackbarAlert>
      )}

      {/* {
                responseAdd?.isError && <SnackbarAlert autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {responseAdd?.data?.message}
                    </Alert>
                </SnackbarAlert>
            } */}
    </>
  );
};

export default Snackbar;

export const NoHideSnackbar = ({ responseAdd }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    handleClick();
    //console.log("click")
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //console.log(responseAdd);
  return (
    <>
      {responseAdd?.isSuccess && (
        <SnackbarAlert
          autoHideDuration={1500}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {responseAdd?.data?.message}
          </Alert>
        </SnackbarAlert>
      )}

      {/* {
                responseAdd?.isError && <SnackbarAlert autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {responseAdd?.data?.message}
                    </Alert>
                </SnackbarAlert>
            } */}
    </>
  );
};

// snackbar for general purpose
export const GeneralSnackbar = () => {
  const message = useSelector(snackBarMessageSelector);
  const [errorMessage, setError] = useState(null);
  const [successMessage, setSuccess] = useState(null);
  const status = useSelector(snackBarStatusSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); // Initialize open state as false
  const { hideDialog } = useJumboDialog();
  console.log("object", status);
  useEffect(() => {
    // Handle showing Snackbar when successMessage or errorMessage is provided
    if (message && status) {
      console.log("status1", status);
      setOpen(true);
      setSuccess(message);
    }
    // else{
    //     setOpen(true);

    // }
    else {
      console.log("in else portion");
      console.log("status", status);
      setOpen(true);
      setError(message);
    }
  }, [message, status]);
  console.log(message, "messagqeasdddd");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (status) {
      hideDialog();
    }
    setOpen(false);
    dispatch(hideSnackBarMessage());
    dispatch(setSnackBarStatusNull());
    setSuccess(null);
    setError(null);
  };

  return (
    <>
      {successMessage && message ? (
        <SnackbarAlert
          autoHideDuration={1500}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={"success"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </SnackbarAlert>
      ) : (
        errorMessage && (
          <SnackbarAlert
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </SnackbarAlert>
        )
      )}
      {/* {errorMessage && message && (
                <SnackbarAlert
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </SnackbarAlert>
            )} */}
    </>
  );
};

///
export const LoginSnackbar = ({ response, message }) => {
  const [open, setOpen] = useState(true);
  const [response1, setResponse1] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    handleClick();
    setResponse1(message);
    setSuccess(response);
    //console.log("click")
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {/* 
            {response && <SnackbarAlert autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </SnackbarAlert>

            } */}

      {success && (
        <SnackbarAlert
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {response1}
          </Alert>
        </SnackbarAlert>
      )}
    </>
  );
};
