import { Config } from 'constant';
import React from 'react';


// export default DZImage = ({style, source}) => (
//     <img style={[{ width:'100%', height:'100%', }, style]} 
//         src={source??{uri : Config.defaultProductImageURI}}
//         resizeMode='contain'
//     />
// )

export function DZImageCircle({ style, image, link, activity = false }) {

    console.log("Image in DzImage ", link + image )
    return (
        <img style={{ width: '100%', height: '100%', borderRadius: 25, ...style }}
             src={image ? link + image : (activity ? Config.defaultActivityImg : Config.defaultImg)}
        />

    )
}

// const styles = StyleSheet.create({});
