import React from "react";
import { useSnackBarManager } from "../../../app/hooks/useSnackBarManager";
const SnackBar = () => {
  const { isShowSnackBar, fnHideSnackBar, isErrorTrue } = useSnackBarManager();
  console.log(isShowSnackBar, "isShowSnackBar");
  return (
    <>
      <div
        style={{ backgroundColor: `${isErrorTrue ? "#E73145" : "#7352c7"}` }}
        className={` ${
          !isShowSnackBar ? "translate-x-[100%]" : " translate-x-0"
        } min-h-[50px] transition duration-300 fixed right-0 bottom-[70px] z-[9999] px-5 py-3 bg-[#7352c7] shadow-lg rounded-lg min-w-[344px]`}
      >
        <span
          style={{ color: `${isErrorTrue ? "#ffffff" : ""}` }}
          className={"text-[#F9F7F7] text-[18px] cursor-text capitalize"}
        >
          {isShowSnackBar}
        </span>
        <span className="absolute h-full top-0 right-0 w-1 bg-DBE2EF">
          <div className="h-full w-full bg-112D4E"></div>
        </span>
      </div>
    </>
  );
};

export default SnackBar;
