import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import React, { useEffect, useState } from "react";
import {
  useGetAllGalleriesQuery,
  BLUE_PRINTS,
  useGetGalleryQuery,
  useDeleteGalleryMutation,
  // useGetAllCompaniesQuery
} from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import DataTable from "../../../@jumbo/dz-components/data-table/DataTable";
import Gallery from "./AddUpdateGallery";
import { setSelectedGallery } from "app/rkt_query/SelectedStuffReducer";
import ViewGallery from "./ViewGallery";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useLocation, useNavigate } from "react-router-dom";

// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box } from "@mui/material";
import { IconButton, Button, Typography } from "@mui/material";
import HeaderButtons, { SimpleButtons } from "@jumbo/dz-components/header-buttons/HeaderButtons";
import Ordering from "@jumbo/dz-components/drag-drop-ordering/Ordering";
import { Config } from "constant";
import DeleteIcon from "@mui/icons-material/Delete";

const GalleryList = () => {
  const location = useLocation();




  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteGallery, responseDelete] = useDeleteGalleryMutation();
  const {
    data: res_get_all,
  } = useGetAllGalleriesQuery();
  // const { data: res_get_all_companies, isLoading: isLoadingCompanies, error: errorCompanies } = useGetAllCompaniesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.gallery;
  bluePrint.addRecord = () => {
    setShowMode(false);
    dispatch(setSelectedGallery(null));
  };
  bluePrint.showRecord = (row) => {
    setShowMode(true);
    dispatch(setSelectedGallery(row));
  };
  bluePrint.editRecord = (row) => {
    setShowMode(false);
    dispatch(setSelectedGallery(row));
  };
  bluePrint.deleteRecord = (row) => {
    dispatch(setSelectedGallery(row));
  };
  bluePrint.handleDelete = (id) => {
    deleteGallery(id);
  };
  const deleteGalleryImage = (id) => {

    showDialog({
      variant: 'confirm',
      title: 'Are you sure about deleting this image ?',
      content: "You won't be able to recover this  later",
      onYes: () => deleteImage(id),
      onNo: hideDialog
    })
  }
  const deleteImage=(id)=>{
    deleteGallery(id);

  }
  const showAddDialog=(id)=>{
    showDialog({
      title: "Add Image",
     
      content: <Gallery />
    });

  }




  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && (
        <SimpleBackdrop responseAdd={responseDelete} />
      )}
      {responseDelete?.isSuccess === true && (
        <Snackbar responseAdd={responseDelete} />
      )}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
      <SimpleButtons onClick={() => { showAddDialog();  }} title="Add Image" />

        <JumboDemoCard
          title={"Gallery"}
          wrapperSx={{
            p: 0,
            backgroundColor: "background.paper",
            "&:last-child": { pb: 0 },
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
              width: "100%",
              gap: "20px",
              padding: "20px",
            }}
          >
            {res_get_all?.data?.galleries?.map((gallery, index) => (
              <div style={{ position: "relative", width: "100%" }}>
                <img
                  src={Config.digitalOceanLinkGallerySmallImg + gallery.image}
                  alt="thumbnail"
                  style={{
                    width: "100%",
                    height: "120px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
                <DeleteIcon
                  sx={{ position: "absolute", top: "10px", right: "10px", color: '#FFFFFF', background: '#7352C7', padding: '4px', borderRadius: '4px' }}
                  onClick={() => deleteGalleryImage(gallery.id)}
                />
              </div>
            ))}
          </div>
        </JumboDemoCard>
      </Box>
    </>
  );
};

export default GalleryList;
