import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Div from "@jumbo/shared/Div";
import { useAddCourseMutation, useUpdateCourseMutation } from 'app/rkt_query/storeApis';
import { useEffect } from 'react';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

const SimpleBackdrop = ({responseAdd, responseUpdate}) => {
    //const [responseAddAdd] = useAddCourseMutation();
    const { hideDialog } = useJumboDialog();
    const [open, setOpen] = React.useState(true);
    
    console.log("in backdrop", responseUpdate);
    return (
            <Div sx={{textAlign: 'center'}}>

                {/* <Button onClick={() => setOpen(!open)}>Show backdrop</Button> */}
                {(responseAdd?.isLoading || responseUpdate?.isLoading ) &&
                   <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999}}
                    open={open}
                    onClick={() => console.log("clicked bakc")}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                
                }
            </Div>
    );
};
export default SimpleBackdrop;