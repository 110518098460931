import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { selectedOrderSelector } from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import { BLUE_PRINTS, useGetOrderQuery } from "app/rkt_query/storeApis";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./order-detail.css";

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

import { DZText } from "app/DZcomponents/DZText";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import DZGenericView from "@jumbo/dz-components/dzgeneric/DZGenericView";
import { Button } from "@mui/material";

const ViewOrder = ({ selectId }) => {
  const selectedOrder = useSelector(selectedOrderSelector);
  const [OrderId, setOrderId] = useState(selectId);
  const {
    data: res_get,
    isLoading: isLoadingOrder,
    error: errorOrder,
  } = useGetOrderQuery(selectedOrder?.id);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();

  const [detail, setDetail] = useState(null);
  const orderContainer = {
    "--primary-color": "#7352C7",
    "--primary-light": "#7352C790",
    "--background-color": "#F6F4FB",
  };

  const orderDetail = {
    "--primary-color": "#7352C7",
    "--primary-light": "#7352C790",
    "--background-color": "rgba(115, 82, 199, 0.04)",
  };

  useEffect(() => {
    setDetail(res_get?.data);
  }, [res_get]);

  const formattedDate = (timeDate) => {
    return new Date(timeDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const received = (timeDate) => {
    const currentTimestamp = new Date();
    const orderTimestamp = new Date(timeDate);
    const timeDifferenceInMilliseconds = currentTimestamp - orderTimestamp;
    return Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
  };
  return (
    <>
     { errorOrder ? <div>{JSON.stringify(errorOrder)}</div>
                : isLoadingOrder ? <div>Loading Single Order.....</div>
                    : res_get?.data ?<div className="detail__section" style={orderDetail}>

        <div className="detail__container order__detail">
          <div className="left__side">
            <span className="text__dark">Order #{detail?.id}</span>
            <span className="text__light">
              {formattedDate(detail?.created_at)}
            </span>
          </div>
          <div className="right__side">
            <div className="personal__detail">
              {<span className="text__dark">{detail?.user?.name}</span>}
              {<span className="text__dark">{detail?.user?.email}</span>}
        
            </div>
            <img
              className="square__img"
              src={Config.digitalOceanLinkUserSmallImg + detail?.user?.image}
              alt=""
            />
          </div>
        </div>
        <div className="border__line"></div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            justifyContent: "flex-end",
          }}
        >
    
          <span className="text__light">
            Order Status:<span className="text__dark">{detail?.order_status}</span>
          </span>
   
        </div>

        <div className="border__line"></div>

        <div className="detail__container">
          <span className="text__light">
            Order Type:<span className="text__dark">{detail?.user_address_id?"Delivery":"PickUp"}</span>
          </span>

          <span className="text__light">
            Order Received:
            <span className="text__dark">
              {received(detail?.created_at)} mins ago
            </span>
          </span>
        </div>

        <div className="border__line"></div>

        <div className="detail__container orders__container">
          <span className="text__dark">Order Menu</span>
          <div className="product__cards">
            {detail?.order_detail?.map((item, index) => (
              <div className="product__card">
                <div className="product__detail">
                  <div className="left__side">
                    <img
                      className="square__img"
                      src={
                        Config.digitalOceanLinkProductSmallImg +
                        item?.product?.image
                      }
                      alt=""
                    />
                    <div className="quantity__detail">
                      <span className="text__light">
                        {item?.product?.title?.[selectedLanguage]}
                      </span>
                      <span className="text__dark">
                        Kr.{item?.price}x{item?.number_of_items}
                      </span>
                    </div>
                  </div>
                  <span className="text__dark">
                    Kr.{item?.price * item?.number_of_items}
                  </span>
                </div>
            
              </div>
            ))}
          </div>
        </div>

        <div className="border__line"></div>

        <div className="detail__container price__container">
          <div className="price__div">
            <span className="text__dark">Sub Total</span>
            <span className="text__light">
              Kr. {detail?.amount + detail?.discount}
            </span>
          </div>
          <div className="price__div">
            <span className="text__dark">Discount</span>
            <span className="text__light">Kr. {detail?.discount}</span>
          </div>
          <div className="price__div">
            <span className="text__dark">Total</span>
            <span className="text__light">Kr. {detail?.amount}</span>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>
            </div>
      </div>
      
      
      : <div>Bad Code</div>}
      
    </>
  );
};

export default ViewOrder;
