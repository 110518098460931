import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage'
import { Config } from 'constant'
import React from 'react'

const DZDefaultImg = ({src, selectedFile}) => {
    return (
        <>
            {src ? (
                <img src={src} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
            ) : (

                <DZImageCircle image={selectedFile} link={Config.digitalOceanLinkCategorySmallImg} style={{
                    height: 100, width: 100, borderRadius: 50, alignSelf: "center",
                }}
                />

            )}
        </>
    )
}

export default DZDefaultImg
