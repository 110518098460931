import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab, Box } from "@mui/material";
import {
  selectedHeroSelector,
  setSelectedHero,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddHeroMutation,
  useGetHeroQuery,
  useUpdateHeroMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  GeneralSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import DZDefaultImg from "app/DZcomponents/DZDefaultImg";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";

const AddUpdateHero = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedHero = useSelector(selectedHeroSelector);
  const {
    data: res_get,
    isLoading: isLoadingHero,
    error: errorHero,
  } = useGetHeroQuery(selectedHero?.id);

  const [updateHero, responseUpdate] = useUpdateHeroMutation();
  const [addHero, responseAdd] = useAddHeroMutation();
  const bluePrint = BLUE_PRINTS.hero;

  const { fnShowSnackBar } = useSnackBarManager();
  const [hero_sliderState, setHeroState] = useState(null);
  const [open, setOpen] = useState(false);

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [show, setShow] = useState(false);
  // const [error, setError] = useState([]);
  // const [count, setCount] = useState(true);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.hero_slider?.image
  );

  const addUpdateHeroData = async (action) => {
    const formData = new FormData();
    formData.append("image", selectedFile ? selectedFile : "");
    formData.append("id", res_get?.data?.hero_slider?.id);
    formData.append("title", hero_sliderState.title);
    setOpen(true);
    try {
      const result =
        action === "add" ? await addHero(formData) : await updateHero(formData);
      if (result?.data?.message) {
        fnShowSnackBar(result?.data?.message);
      } else {
        fnShowSnackBar(result?.error?.data?.message, true);
      }
      // unwrapResult(result);
      setShow(true);
      console.log(result);
      // // Handle success message
      // return { success: true, message: result?.message }; // Return success message
    } catch (error) {
      // Handle error message
      console.log("Error:", error);
      setShow(true);

      // fnShowSnackBar( {success: false,message:error?.data?.message});
      fnShowSnackBar(error?.data?.message, false);
    }
    // action === "add" ? addHero(formData) : updateHero(formData);
  };

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    res_get?.data?.hero_slider?.image &&
      setSelectedFile(res_get?.data?.hero_slider?.image);
    setHeroState(selectedHero ? res_get?.data?.hero_slider : bluePrint);
  }, [res_get]);

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <SnackAndBackDrop
        showBackDrop={showBackDrop}
        responseAdd={responseAdd}
        responseUpdate={responseUpdate}
        setOpen={setOpen}
        open={open}
      />

      {hero_sliderState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* image start */}
          <DZDefaultImg src={imagePreview} selectedFile={selectedFile} />

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>

          <DZTextValidation
            error={error?.[0]}
            label="Title"
            variant="outlined"
            value={hero_sliderState?.title}
            onChange={(e) => {
              setHeroState((x) => ({ ...x, title: e.target.value }));
            }}
            placeholder="Title"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedHero(null));
              }}
            >
              Cancel
            </Button>
            {selectedHero?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateHeroData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateHeroData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedHero(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateHero;
