import React, { useState, useEffect } from "react";
import "./order-detail.css";
import { useGetAllPendingOrdersQuery, useUpdateRestaurantOrderMutation } from "app/rkt_query/storeApis";
import { useSelector } from "react-redux";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import { Config } from "constant";
import { Box, Button, CircularProgress } from "@mui/material";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import HeaderButtons from "@jumbo/dz-components/header-buttons/HeaderButtons";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { GeneralSnackbar } from "@jumbo/dz-components/snackbar/Snackbar";
import { Input } from '@mui/material/Input';

const OrderDetail = () => {
  const [detail, setDetail] = useState(null);
  const [orders, setOrders] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { data: res_get_all, isLoading: isLoadingOrder, error: errorOrder  } = useGetAllPendingOrdersQuery();
  const [updateOrder, responseUpdateOrder,] = useUpdateRestaurantOrderMutation();
  const { showDialog, hideDialog } = useJumboDialog();
  const [activeOrder, setActiveOrder] = useState(null);
  const { fnShowSnackBar } = useSnackBarManager();
  const [show, setShow] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const orderContainer = {
    "--primary-color": "#7352C7",
    "--primary-light": "#7352C790",
    "--background-color": "#F6F4FB",
  };

  const orderDetail = {
    "--primary-color": "#7352C7",
    "--primary-light": "#7352C790",
    "--background-color": "rgba(115, 82, 199, 0.04)",
  };
  const selectedLanguage = useSelector(selectedLanguageSelector);
  useEffect(() => {
    const firstOrder = res_get_all?.data?.orders?.[0];
    setActiveOrder(firstOrder);
    setOrders(res_get_all?.data?.orders);
    setDetail(firstOrder);
  }, [res_get_all]);

  const changeStatus = (status, id) => {

    showDialog({
      variant: "confirm",
      title: "Are you sure you want to change status?",
      showLanguageSwitcher: false,
      // content: "You won't be able to recover this " + btnTitleAdd + ' later',
      onYes: () => handleUpdateStatus(status, id),
      onNo: hideDialog,
    });
  };
  const handleUpdateStatus = async (status, id) => {
    // bluePrint.handleDelete(id);

    try {
      const result = await updateOrder({ order_status: status, id: id });

      if (result?.data?.message) {
        fnShowSnackBar(result?.data?.message, true);
        hideDialog();
      } else {
        fnShowSnackBar(result?.error?.data?.message, false);

      }

      // unwrapResult(result);
      setShow(true);
      console.log(result);

      // // Handle success message
      // return { success: true, message: result?.message }; // Return success message
    } catch (error) {
      // Handle error message
      console.log('Error:', error);
      setShow(true)

      // fnShowSnackBar( {success: false,message:error?.data?.message});
      fnShowSnackBar(error?.data?.message, false);

    }

  };


  const formattedDate = (timeDate) => {
    return new Date(timeDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const received = (timeDate) => {
    const currentTimestamp = new Date();
    const orderTimestamp = new Date(timeDate);
    const timeDifferenceInMilliseconds = currentTimestamp - orderTimestamp;
    return Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
  };
  useEffect(() => {

    const filteredOrders = res_get_all?.data?.orders?.filter(order =>
      orderMatchesSearchCriteria(order, searchQuery)
    );

    setOrders(filteredOrders || []);
  }, [searchQuery, res_get_all?.data?.orders]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const orderMatchesSearchCriteria = (order, searchQuery) => {
    const lowercaseSearchQuery = searchQuery.toLowerCase();
    return order.id.toString().includes(searchQuery) ||
      order.order_source.toLowerCase().includes(lowercaseSearchQuery) ||
      orderCreatedDateMatches(order, lowercaseSearchQuery);
  };

  const orderCreatedDateMatches = (order, searchQuery) => {
    const formattedOrderDate = new Date(order?.created_at).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).replaceAll("/", "-");
    return formattedOrderDate.includes(searchQuery);
  };
  return (
    <>
      {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateOrder} />}

      {show && <GeneralSnackbar />}
      {res_get_all?.data?.orders ? (
      <div className="order__container" style={orderContainer}>
        
          <div className="order__section">
            <div className="type__toggler">
              <span className="tab pre__paid">Pending Orders</span>
              {/* <span className="tab">Prepaid</span> */}

            </div>

            <input
              style={{ padding: '10px 16px', borderRadius: '10px', border: '1px solid #7352C7', color: '#7352C7', fontSize: '16px', fontWeight: '500' }}
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />

          <div className="order__div">
              {orders?.map((order, index) => {
                return (
                  <div
                    //  className={`order__card   ${index==0?"active__card":""}`}
                    key={index}
                    className={`order__card ${activeOrder === order ? "active__card" : ""
                      }`}
                    onClick={() => {
                      setActiveOrder(order);
                      setDetail(order);
                    }}
                  >
                    <div className="card__content">
                      <span className="text__dark">Order #{order?.id}</span>
                      <span className="text__light">
                        {formattedDate(order?.created_at)}
                      </span>
                    </div>
                    {/* {index==0?setDetail(order):''} */}
                    <div className="detail__opener">
                      <div className="price__container">
                        <span className="text__dark">
                          From:{" "}
                          <span className="text__light">
                            {order?.order_source}
                          </span>
                        </span>

                        <span className="text__normal">Kr.{order?.amount}</span>
                      </div>

                      <svg
                        className="forward__arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width={28}
                        height={28}
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <rect
                          width={28}
                          height={28}
                          rx={10}
                          fill="var(--primary-light)"
                        />
                        <path
                          d="M11 9L16.0781 13.2318C16.5579 13.6316 16.5579 14.3684 16.0781 14.7682L11 19"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                );
              })}
            </div> 
          </div>
       

        {detail ? (
          <div className="detail__section" style={orderDetail}>
            <div className="detail__container order__detail">
              <div className="left__side">
                <span className="text__dark">Order #{detail?.id}</span>
                <span className="text__light">
                  {formattedDate(detail?.created_at)}
                </span>
              </div>
              <div className="right__side">
                <div className="personal__detail">
                  {<span className="text__dark">{detail?.user?.name}</span>}
                  {/* <span className="text__light">
                Order From:
                <span className="text__dark">{detail?.order_source}</span>
              </span> */}
                </div>
                <img
                  className="square__img"
                  src={Config.digitalOceanLinkUserSmallImg + detail?.user?.image}
                  alt=""
                />
              </div>
            </div>
            <div className="border__line"></div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                justifyContent: "flex-end",
              }}
            >
              <span className="text__light">Order Status</span>

              <select
                style={{
                  width: "100%",
                  padding: "7px 6px",
                  borderRadius: "10px",
                  border: "0",
                  background: "#7352C7",
                  color: "#fff",
                  outline: 'none'
                }}
                value={detail?.order_status}
                onChange={(e) => { changeStatus(e.target.value, detail?.id); setShowBackDrop(true); }}
              >
                <option value="completed" style={{ background: "#FFF", color: "#7352C7" }}>
                  Completed
                </option>
                <option value="cancelled" style={{ background: "#FFF", color: "#7352C7" }}>
                  Cancelled
                </option>
                <option value="pending" style={{ background: "#FFF", color: "#7352C7" }}>
                  Pending
                </option>
              </select>
            </div>

            <div className="border__line"></div>

            <div className="detail__container">
              <span className="text__light">
                Order Type:<span className="text__dark">Take Away</span>
              </span>

              <span className="text__light">
                Order Received:
                <span className="text__dark">
                  {received(detail?.created_at)} mins ago
                </span>
              </span>
            </div>

            <div className="border__line"></div>
            <div className="detail__container orders__container">
              <span className="text__dark">Order Menu</span>
              <div className="product__cards">
                {detail?.order_detail?.map((item, index) => (
                  <div className="product__card">
                    <div className="product__detail">
                      <div className="left__side">
                        <img
                          className="square__img"
                          src={
                            Config.digitalOceanLinkProductSmallImg +
                            item?.product?.image
                          }
                          alt=""
                        />
                        <div className="quantity__detail">
                          <span className="text__light">
                            {item?.product?.title?.[selectedLanguage]}
                          </span>
                          <span className="text__dark">
                            Kr.{item?.price}x{item?.number_of_items}
                          </span>
                        </div>
                      </div>
                      <span className="text__dark">
                        Kr.{item?.price * item?.number_of_items}
                      </span>
                    </div>
                    {/* <div className="product__accessories">

              {item?.order_accessory.map( (item)=>( <div className="accessory__li">
                  <div className="accessory__detail">
                    <div className="circle" />
                    <span className="text__dark">
                      {item?.accessory?.title?.[selectedLanguage]}
                      <span className="text__normal"> Kr. {item?.price}x {item?.number}</span>
                    </span>
                  </div>
                  <span className="text__normal">Kr.{item?.price *item?.number}</span>
                </div>
              ))
              }
              </div> */}
                  </div>
                ))}
              </div>
            </div>
            <div className="border__line"></div>
            <div className="detail__container price__container">
              <div className="price__div">
                <span className="text__dark">Sub Total</span>
                <span className="text__light">
                  Kr. {detail?.amount + detail?.discount}
                </span>
              </div>
              <div className="price__div">
                <span className="text__dark">Discount</span>
                <span className="text__light">Kr. {detail?.discount}</span>
              </div>
              <div className="price__div">
                <span className="text__dark">Total</span>
                <span className="text__light">Kr. {detail?.amount}</span>
              </div>

            </div>
          </div>
        ) : (
          "Select Order"
        )}
      </div>
      ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
    </>
  );
};

export default OrderDetail;
