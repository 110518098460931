import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  selectedSizeSelector,
  setSelectedSize,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddSizeMutation,
  useGetSizeQuery,
  useUpdateSizeMutation,
  useGetAllSizeGroupsQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateSize = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedSize = useSelector(selectedSizeSelector);
  const {
    data: res_get,
    isLoading: isLoadingSize,
    error: errorSize,
  } = useGetSizeQuery(selectedSize?.id);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [updateSize, responseUpdate] = useUpdateSizeMutation();
  const [addSize, responseAdd] = useAddSizeMutation();
  const bluePrint = BLUE_PRINTS.size;
  const {
    data: res_get_all,
    isLoading: isLoadingSizeGroups,
    error: errorSizeGroups,
  } = useGetAllSizeGroupsQuery();
  const { fnShowSnackBar } = useSnackBarManager();
  const [sizeState, setSizeState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);

  const addUpdateSizeData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.size?.id);

    formData.append("size_group_id", sizeState.size_group_id);
    formData.append("title", JSON.stringify(sizeState.title));
    formData.append("short_title", JSON.stringify(sizeState.short_title));
    formData.append("default", sizeState.default ? 1 : 0);
    setOpen(true);
    // return false;
    const res = action === "add" ? await addSize(formData) : await updateSize(formData);

    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar("Something went wrong", true);
    }
  };
  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  const size_groups = res_get_all?.data?.size_groups_sizes?.map(
    (size_groups) => size_groups
  );

  useEffect(() => {
    setSizeState(
      selectedSize
        ? res_get?.data?.size
        : { ...bluePrint, size_group_id: res_get?.data?.size?.size_group_id }
    );
  }, [res_get]);

  return (
    <div>
      <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />
      {sizeState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* image start */}
          <DZDropdown
            error={error?.size_group_id}
            fieldTextName={"short_title"}
            value={sizeState?.size_group_id}
            data={size_groups}
            onChange={(e) => {
              setSizeState((x) => ({
                ...x,
                size_group_id: e.target.value,
              }));
            }}
            placeholder="Select Size"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sizeState?.default == 0 ? false : true}
                onChange={(e) => {
                  setSizeState((x) => ({ ...x, default: e.target.checked }));
                }}
              />
            }
            label="Default Price On Product "
          />
          {/* <DZDropdown error={error?.size_group_id} placeholder={'Select Company'} value={sizeState?.size_group_id} data={companies}
            onChange={(e) => { setSizeState(x => ({ ...x, company_id: e.target.value, })) }} /> */}
          {/* 
          <DZSimpleDropDown value={courseState?.order_type} data={order_type} name="reply_type"
            placeholder="Select Order Type" onChange={(e) => { setSizeState(x => ({ ...x, order_type: e.target.value, })) }} />

          <DZDropdown placeholder={'Select Team'} value={courseState?.team_id} data={company_teams}
            onChange={(e) => { setSizeState(x => ({ ...x, team_id: e.target.value, })) }} /> */}

          <DZTextValidation
            error={error?.title}
            label="Title"
            variant="outlined"
            value={sizeState?.title?.[selectedLanguage]}
            onChange={(e) => {
              setSizeState((x) => ({
                ...x,
                title: { ...x.title, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Title"
          />

          <DZTextValidation
            error={error?.short_title}
            label="Short Title"
            variant="outlined"
            value={sizeState?.short_title?.[selectedLanguage]}
            onChange={(e) => {
              setSizeState((x) => ({
                ...x,
                short_title: {
                  ...x.short_title,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Short Title"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedSize(null));
              }}
            >
              Cancel
            </Button>
            {selectedSize?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateSizeData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateSizeData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedSize(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateSize;
