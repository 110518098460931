import React, { useState } from "react";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AppsIcon from '@mui/icons-material/Apps';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LockClockIcon from '@mui/icons-material/LockClock';
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { ROLE } from "app/rkt_query/SettingsReducer";
import useCheckRoles from "@jumbo/dz-components/custom-hooks/useCheckRoles";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { Config } from "constant";
import { selectedUserLoginSelector } from "app/rkt_query/SelectedStuffReducer";
import { useSelector } from "react-redux";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import CollectionsIcon from '@mui/icons-material/Collections';

// useCheckRoles()
//  const GetFromRedux = () => {
//     const [allowances, setAllowances] = useState([]);
//  const roleName= useSelector(selectedUserLoginSelector)
//  setAllowances(roleName)  
//  return  allowances;

//     }
export const checkRole = (status) => {
    switch (status) {
        case ROLE.Admin:
        return ROLE.Admin;
      case ROLE.Manager:
        return ROLE.Manager;
      case ROLE.Owner:
        return ROLE.Owner;
      default:
        return ROLE.Manager;
      
    }
  };
  
  const role=decryptStoredData(Config.roleName)
  
  export const adminOwnerCondition=()=>{
//   const role_name=GetFromRedux();
    return (checkRole(role) === ROLE.Admin || checkRole(role) === ROLE.Owner);
  }
  export const adminOwnerManagerCondition=()=>{
//   const role_name=GetFromRedux();
    return (checkRole(role) === ROLE.Admin || checkRole(role) === ROLE.Owner|| checkRole(role) === ROLE.Manager);
  }
  export const adminCondition=()=>{

    return checkRole(role) === ROLE.Admin?true:false ;
  }
//   const adminManagerReceptionistCondition=()=>{

//     return checkRole("admin") === ROLE.Admin || checkRole("admin") === ROLE.Manager || checkRole("admin") === ROLE.Receptionist;
//   }




const menus = [
    
    {

        label: 'home',
        type: "section",
        children: [
            {
                uri: "/",
                label: 'Dashboard',
                type: "nav-item",
                visible: true,
                icon: <DashboardIcon sx={{ fontSize: 20 }} />,
            },
            // {
                
            //     uri: "/order-list",
            //     label: 'Orders List',
            //     type: "nav-item",
            //     visible: true,
            //     icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

            // },
            {
                
                uri: "/order-detail",
                label: 'New Orders',
                type: "nav-item",
                visible: true,
                icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

            },
            // {
                
            //     uri: "/order-table",
            //     label: 'Table Orders',
            //     type: "nav-item",
            //     visible: true,
            //     icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

            // },
           
        ]

    },

    adminOwnerManagerCondition()&&{
        label: 'modules',
        type: "section",
        children: [
              // {
            //     uri: "/courses",
            //     label: 'Course',
            //     type: "nav-item",
            //     icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            {
                uri: "/orders",
                label: 'All Orders',
                type: "nav-item",
                visible: true,
                icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/categories",
                label: 'Category',
                type: "nav-item",
                visible: true,
                icon: <CategoryIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/sub-categories",
                label: 'Sub Category',
                type: "nav-item",
                visible: true,
                icon: <DynamicFeedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/products",
                label: 'Product',
                type: "nav-item",
                 visible: true,

                icon: <ProductionQuantityLimitsIcon sx={{ fontSize: 20 }} />
            },
            // {
            //     uri: "/accessories",
            //     label: 'Accessory',
            //     type: "nav-item",
            //     visible: true,

            //     icon: <AppsIcon sx={{ fontSize: 20 }} />
            // },
            {
                uri: "/size-groups",
                label: 'Size Group',
                type: "nav-item",
                visible: true,

                icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/hero",
                label: 'Hero Slider',
                type: "nav-item",
                visible: true,

                icon: <DevicesOtherIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/gallery",
                label: 'Gallery',
                type: "nav-item",
                visible: true,

                icon: <CollectionsIcon sx={{ fontSize: 20 }} />
            },
            // {
            //     uri: "/orders",
            //     label: 'Orders',
            //     type: "nav-item",
            //     visible: true,

            //     icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
            // },
           

        ]

    },



    adminOwnerCondition()&&{
        label: 'Shop',
        type: "section",
        children: [
            {
                label: 'Shop',
                type: "collapsible",
                icon: <ImportContactsOutlinedIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/shop-info",
                        label: 'Shop Info',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <ImportContactsOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/shop-time",
                        label: 'Shop Timing',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <AccessTimeOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/delivery-time",
                        label: 'Delivery Timing',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <LockClockIcon sx={{ fontSize: 20 }} />
                    },
                    // {
                    //     uri: "/shop-table",
                    //     label: 'Shop Table',
                    //     type: "nav-item",
                    //     visible: adminOwnerCondition(),
                    //     icon: <TableBarOutlinedIcon sx={{ fontSize: 20 }} />
                    // },
                    {
                        uri: "/shop-feature",
                        label: 'Shop Feature',
                        type: "nav-item",
                        visible: adminCondition(),
                        icon: <FeaturedPlayListOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/delivery-post-codes",
                        label: 'Delivery PostCode',
                        type: "nav-item",
                        visible: adminCondition(),
                        icon: <FeaturedPlayListOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    
                ]
            },
        
           
        ]
    },




    adminOwnerCondition()&&{

        label: 'authPages',
        type: "section",
        children: [
           
            {
                label: 'Auth',
                type: "collapsible",
                icon: <GroupOutlinedIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri:"/users",
                        label: 'Customer',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    // {
                    //     uri: "/posc",
                    //     label: 'Ssk Users',
                    //     type: "nav-item",
                    //     visible: adminOwnerCondition(),
                    //     icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
                    //     is_super:true
                    // },
                    {
                        uri: "/admins",
                        label: 'Admin',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
                        is_super:true
                    },
                    
                ]
            },

            
            // {
            //     uri: "/restaurants",
            //     label: 'Shop',
            //     type: "nav-item",
            //     visible: true,
            //     icon: <AppsIcon sx={{ fontSize: 20 }} />,
            //     is_super:true,

            // },
            // {
            //     uri: "/shop-info",
            //     label: 'Shop Info',
            //     type: "nav-item",
            //     visible: adminOwnerCondition(),
            //     icon: <ImportContactsOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/shop-time",
            //     label: 'Shop Timing',
            //     type: "nav-item",
            //     visible: adminOwnerCondition(),
            //     icon: <AccessTimeOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/shop-table",
            //     label: 'Shop Table',
            //     type: "nav-item",
            //     visible: adminOwnerCondition(),
            //     icon: <TableBarOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/shop-feature",
            //     label: 'Shop Feature',
            //     type: "nav-item",
            //     visible: adminCondition(),
            //     icon: <FeaturedPlayListOutlinedIcon sx={{ fontSize: 20 }} />
            // },




            
          
        
            {
                uri: "/contacts",
                label: 'Contacts',
                type: "nav-item",
                visible: adminOwnerCondition(),
                icon: <ContactPhoneOutlinedIcon sx={{ fontSize: 20 }} />,
                is_super:true
            },
          
        ]

    },
  

];



export default menus;



    