import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import React, { useState } from "react";
import {
  useGetAllProductsQuery,
  BLUE_PRINTS,
  useDeleteProductMutation,
  useUploadProductsFileMutation,
  useDownloadProductsFileQuery,
} from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import DataTable from "../../../@jumbo/dz-components/data-table/DataTable";
import { setSelectedProduct } from "app/rkt_query/SelectedStuffReducer";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, { GeneralSnackbar } from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ViewProduct from "./ViewProduct";
import AddUpdateProduct from "./AddUpdateProduct";
import { SimpleButtons } from "@jumbo/dz-components/header-buttons/HeaderButtons";
import Ordering from "@jumbo/dz-components/drag-drop-ordering/Ordering";
import { Config } from "constant";
import axios from "axios";
import { setSnackBarMessage, setSnackBarStatus } from "app/rkt_query/SnackBarReducer";

const ProductList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const {
    data: res_get_all,
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useGetAllProductsQuery();
  const { refetch } = useDownloadProductsFileQuery();
  const [deleteProduct, responseDelete] = useDeleteProductMutation();
  const [uploadProductsFile, { isLoading: isLoadingProductsFile }] =
    useUploadProductsFileMutation();

  const { showDialog, hideDialog } = useJumboDialog();

  const bluePrint = BLUE_PRINTS.product;
  bluePrint.addRecord = () => {
    setShowMode(false);
    dispatch(setSelectedProduct(null));
  };
  bluePrint.showRecord = (row) => {
    setShowMode(true);
    dispatch(setSelectedProduct(row));
  };
  bluePrint.editRecord = (row) => {
    setShowMode(false);
    dispatch(setSelectedProduct(row));
  };
  bluePrint.deleteRecord = (row) => {
    dispatch(setSelectedProduct(row));
  };
  bluePrint.handleDelete = (id) => {
    deleteProduct(id);
  };

  const handleBack = () => {
    navigate("/categories");
  };

  const handleUploadFile = (event) => {
    const file = event.target.files[0];
    console.log(file, "file");
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      uploadProductsFile(formData)
        .unwrap()
        .then((res) => {
          console.log(res);
          dispatch(setSnackBarMessage("File uploaded successfully"));
          dispatch(setSnackBarStatus("404"));
          event.target.value = null;
        })
        .catch((error) => {
          dispatch(setSnackBarMessage(error.data.message|| "Error uploading file"));
          // dispatch(setSnackBarStatus(error?.data?.status || "404"));
          console.log("Error uploading file:", error.data.message);
        });
    }
  };

  const handleUploadProducts = () => {
    // navigate("/product/uploadproducts");
  };
  const handleDownload = async () => {
    try {
      const response = await axios.get(
        Config.serverUrl +
        "api/admin/download-product-format?nocache=" +
        Date.now(),
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "product_Format.xlsx";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <>
    <GeneralSnackbar/>
      <Snackbar responseAdd={responseDelete} />
      <div className="flex justify-end items-center gap-4 mb-4 w-full ">
        <SimpleButtons
          title={"Product Ordering"}
          onClick={() =>
            showDialog({
              title: "Product List",
              size: "xl",
              content: (
                <Ordering
                  dataList={res_get_all?.data?.products}
                  table={"product"}
                />
              ),
            })
          }
        />
      </div>
      <JumboDemoCard
        title="Products"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >
        <div style={{ width: "100%" }}>
          <div className="flex justify-end items-center gap-4 mb-4 w-full ">
            <div
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              className="flex items-center justify-center px-[16px] mr-4 py-[8px] text-[14px] opacity-85 rounded-[4px] cursor-pointer relative bg-[#5E3BB7] text-[#FFF] w-[200px]"
            >
              <span className="cursor-pointer">Upload Products CSV</span>
              <input
                type="file"
                className="absolute h-full opacity-0 cursor-pointer"
                style={{ cursor: "pointer" }}
                onChange={handleUploadFile}
              />
            </div>
            <div
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={handleDownload}
              className="flex items-center justify-center px-[16px] mr-4 py-[8px] text-[14px] opacity-85 rounded-[4px] cursor-pointer bg-[#5E3BB7] text-[#FFF] w-[200px]"
            >
              <span className="cursor-pointer">Download Products CSV</span>
            </div>
          </div>
          <DataTable
            btnTitleAdd="Product"
            data={res_get_all?.data?.products}
            bluePrint={bluePrint}
            ViewModel={() => <ViewProduct />}
            ModalContent={() => (
              <AddUpdateProduct onClose={hideDialog} fullscreen={true} />
            )}
            showAddButton={true}
            viewTitle="Add Product Accessory"
            showHideViewTitle={false}
          />
        </div>
      </JumboDemoCard>
    </>
  );
};

export default ProductList;
