import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {
  useGetAllDeliveryPostCodesQuery, BLUE_PRINTS, useDeleteDeliveryPostCodeMutation,
  // useGetAllCompaniesQuery
} from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import DeliveryPostCode from './AddUpdateDeliveryPostCode';
import { setSelectedDeliveryPostCode } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';




const DeliveryPostCodeList = () => {

  const location = useLocation();
 


  // console.log("In DeliveryPostCode company State ", location?.state?.company);
  console.log("In DeliveryPostCode Relational Objext", location?.state);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteDeliveryPostCode, responseDelete] = useDeleteDeliveryPostCodeMutation();
  const { data: res_get_all, isLoading: isLoadingDeliveryPostCodes, error: errorDeliveryPostCodes } = useGetAllDeliveryPostCodesQuery();
  // const { data: res_get_all_companies, isLoading: isLoadingCompanies, error: errorCompanies } = useGetAllCompaniesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.deliveryPostCode
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedDeliveryPostCode(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedDeliveryPostCode(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedDeliveryPostCode(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedDeliveryPostCode(row)); }
  bluePrint.handleDelete = (id) => { deleteDeliveryPostCode(id); }




  
const size="xl";
  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
    
        <JumboDemoCard
          title={"DeliveryPostCode"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="DeliveryPostCode"
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.post_codes}
              bluePrint={bluePrint}
              ModalContent={() => <DeliveryPostCode onClose={hideDialog} />}
              showAddButton={false}
              hideViewBtn={false}
            />
         
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default DeliveryPostCodeList