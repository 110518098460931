import React, { useState } from 'react';
import { Card, CardContent, IconButton, TextField, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useNavigate } from "react-router-dom";
import PP2Service from './../../../services/index';
import { Config } from './../../../../constant/index';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from "@mui/lab/LoadingButton";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ForgotPassword = () => {
    const { setActiveLayout } = useJumboApp();
    const [email, setEmail] = useState();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(true);
    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate();
    const handleToClose = (event, reason) => {
        if ("clickaway" == reason) return;
        setOpen(false);

    };
    const handler = async () => {

        setLoading(true);
        let response = await PP2Service.fetch("admin-forgot-password", "post", {
            email: email,

        });

        if (response.success) {
            // Saved email to local storage
            setResponse(response)
            localStorage.setItem(Config.email, email)
            navigate('/verify-forgot-password')
            setLoading(false);


        } else {
            setOpen(true)
            setLoading(false);
            setResponse(response)

        }

    }
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    }, []);


    return (
        <>
            <Snackbar autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleToClose}  >

                <Alert onClose={handleToClose} severity="error" sx={{ width: '100%' }}>
                    {response?.message}
                </Alert>
            </Snackbar>
            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(4),
            }}>
                <Div sx={{ mb: 3, display: 'inline-flex' }}>
                    <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                        <img src={`${ASSET_IMAGES}/logo.jpg`} alt="Jumbo React"  width="228px"/>
                        {/* <img src={`${ASSET_IMAGES}/logo.png`} alt="Jumbo React"  width="228px"/> */}
                    </Link>
                </Div>
                <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
                    <Div sx={{ position: 'relative', height: '200px' }}>
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="200"
                            image={getAssetPath(`${ASSET_IMAGES}/logo.jpg`)}
                            // image={getAssetPath(`${ASSET_IMAGES}/applogo.png`)}
                        />
                        <Div sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme => alpha(theme.palette.common.black, .5),
                            p: theme => theme.spacing(3),
                        }}>
                            <Typography
                                variant={"h2"}
                                sx={{
                                    color: 'common.white',
                                    fontSize: '1.5rem',
                                    mb: 0
                                }}>
                                Forgot password
                            </Typography>
                        </Div>
                    </Div>
                    <CardContent>
                        <Div sx={{ mb: 3, mt: 1 }}>
                            <TextField
                                // sx3mb={3}  these props don't work here
                                // sx3mt={1}
                                required
                                fullWidth
                                type="email"
                                id="email"
                                label="Email"
                                onChange={(txt) => setEmail(txt.target.value)}
                            // defaultValue="demo@example.com"
                            />
                        </Div>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            onClick={handler}

                            loading={loading}
                        >Reset Password</LoadingButton>
                        {/* <Button fullWidth variant="contained" size="large" sx={{ mb: 3 }} onClick={handler}>Reset Password</Button> */}
                        {/* <Typography textAlign={"center"} variant={"body1"} mb={1}>Don't remember your email? <Link
                        underline="none" href="#">Contact Support</Link></Typography> */}
                    </CardContent>
                </Card>
                {/* <Typography variant={"body1"} mb={2}>Or sign up with</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                    sx={{
                        bgcolor: '#385196',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#385196',
                        }
                    }}
                    aria-label="Facebook"
                >
                    <Facebook fontSize="small" />
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#00a8ff',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#00a8ff',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Twitter fontSize="small" />
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#23272b',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#23272b',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Google fontSize="small" />
                </IconButton>
            </Stack> */}
            </Div>
        </>
    );
};

export default ForgotPassword;
