import { createSlice } from '@reduxjs/toolkit';


export const slice = createSlice({
    name: 'SelectedStuffReducer',
    initialState: {
        selectedCategory: null,
        selectedDeliveryPostCode: null,
        selectedSubCategory: null,
        selectedGallery: null,
        selectedUserLogin: null,
        selectedProduct: null,
        selectedOrder: null,
        selectedProductAccessory: null,
        selectedUser: null,
        selectedAdmin: null,
        selectedContact: null,
        selectedRestaurant: null,
        selectedSizeGroup: null,
        selectedSize: null,
        selectedHero: null,

     
    

    },
    reducers: {
        setSelectedUserLogin: (state, action) => {
            state.selectedUserLogin = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedGallery: (state, action) => {
            state.selectedGallery = action.payload;
        },
        setSelectedDeliveryPostCode: (state, action) => {
            state.selectedDeliveryPostCode = action.payload;
        },
        setSelectedSubCategory: (state, action) => {
            state.selectedSubCategory = action.payload;
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload;
        },
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload;
        },
        setSelectedOrderAccessory: (state, action) => {
            state.selectedProductAccessory = action.payload;
        },
        setSelectedRestaurant: (state, action) => {
            state.selectedRestaurant = action.payload;
        },

        setSelectedUser: (state, action) => {
            console.log("redux data", action)
            state.selectedUser = action.payload;
        },
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload;
        },
        setSelectedContact: (state, action) => {
            state.selectedContact = action.payload;
        },
      
        setSelectedSizeGroup: (state, action) => {
            console.log("redux data", action)
            state.selectedSizeGroup = action.payload;
        },
        setSelectedSize: (state, action) => {
            state.selectedSize = action.payload;
        },
        setSelectedHero: (state, action) => {
            state.selectedHero = action.payload;
        },
        //old
 
   
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        resetSelectedStuffReducer: (state) => {
            state.selectedUserLogin = null;
            state.selectedCategory = null;
            state.selectedGallery = null;
            state.selectedDeliveryPostCode = null;
            state.selectedSubCategory = null;
            state.selectedProduct = null;
            state.selectedOrder = null;
            state.selectedProductAccessory = null;
            state.selectedUser = null;
            state.selectedAdmin = null;
            state.selectedContact = null;
            state.selectedSizeGroup = null;
            state.selectedSize = null;
            state.selectedHero = null;

          
        },
    },
});

export const {
    setSelectedUserLogin,
    setSelectedCategory,
    setSelectedGallery,
    setSelectedDeliveryPostCode,
    setSelectedSubCategory,
    setSelectedProduct,
    setSelectedOrder,
    setSelectedAccessory,
    setSelectedProductAccessory,
    setSelectedRestaurant,
    setSelectedRestaurantTable,
    setSelectedUser,
    setSelectedAdmin,
    setSelectedContact,
    setSelectedPoscAdmin,
    setSelectedSizeGroup,
    setSelectedSize,
    setSelectedHero,
    //old
 
} = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const selectedUserLoginSelector = (state) => state.SelectedStuffReducer.selectedUserLogin;
export const selectedCategorySelector = (state) => state.SelectedStuffReducer.selectedCategory;
export const selectedGallerySelector = (state) => state.SelectedStuffReducer.selectedGallery;
export const selectedDeliveryPostCodeSelector = (state) => state.SelectedStuffReducer.selectedDeliveryPostCode;
export const selectedSubCategorySelector = (state) => state.SelectedStuffReducer.selectedSubCategory;
export const selectedProductSelector = (state) => state.SelectedStuffReducer.selectedProduct;
export const selectedOrderSelector = (state) => state.SelectedStuffReducer.selectedOrder;
export const selectedProductAccessorySelector = (state) => state.SelectedStuffReducer.selectedProductAccessory;
export const selectedRestaurantSelector = (state) => state.SelectedStuffReducer.selectedRestaurant;
export const selectedUserSelector = (state) => state.SelectedStuffReducer.selectedUser;
export const selectedAdminSelector = (state) => state.SelectedStuffReducer.selectedAdmin;
export const selectedContactSelector = (state) => state.SelectedStuffReducer.selectedContact;
export const selectedSizeGroupSelector = (state) => state.SelectedStuffReducer.selectedSizeGroup;
export const selectedSizeSelector = (state) => state.SelectedStuffReducer.selectedSize;
export const selectedHeroSelector = (state) => state.SelectedStuffReducer.selectedHero;
//old



export default slice.reducer;