import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedSizeSelector } from 'app/rkt_query/SelectedStuffReducer';
import { BLUE_PRINTS, useGetSizeQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';


const ViewSize = ({ selectId }) => {

    const selectedSize = useSelector(selectedSizeSelector);
    const [SizeId, setSizeId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingSize, error: errorSize } = useGetSizeQuery(SizeId);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
    
        setSizeId(selectedSize ? selectedSize?.id : selectId)
    }, [])
    const sizeArray =
        [   { label: 'Group', data: res_get?.data?.size?.size_group?.title },
            { label: 'Title', data: res_get?.data?.size?.title[selectedLanguage]  },
            { label: 'Short Title', data: res_get?.data?.size?.short_title[selectedLanguage]  },
            { label: 'Default', data: res_get?.data?.size?.default?"True":"False" },

        ]
 
    return (
        <div>

            {errorSize ? <div>{JSON.stringify(errorSize)}</div>
                : isLoadingSize ? <div>Loading Single Size.....</div>
                    : res_get?.data?.size ?
                        <DZGenericView obj={sizeArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewSize

