import React,{useState,useEffect} from 'react'
import { Grid } from "@mui/material";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { useGetCurrentYearOrdersQuery,useGetCountOrdersQuery} from "app/rkt_query/storeApis";
import Chart from '@jumbo/dz-components/chart/Chart';
import LineChartOrder from '@jumbo/dz-components/chart/LineChartOrder';
import ProjectCounterCard from './../../shared/widgets/ProjectCounterCard/ProjectCounterCard';
import TasksCounterCard from './../../shared/widgets/TasksCounterCard/TasksCounterCard';
import FilesCounterCard from './../../shared/widgets/FilesCounterCard/FilesCounterCard';
import TeamsCounterCard from './../../shared/widgets/TeamsCounterCard/TeamsCounterCard';
import SalesStatistics from './../../../@jumbo/dz-components/SalesStatistics/SalesStatistics';





const OrderChart = () => {
    const { data: res_get_all, isLoading: isLoadingOrder, error: errorOrder } = useGetCurrentYearOrdersQuery();
    const { data: res_get_all_order_count, isLoading: isLoadingOrderCount, error: errorOrderCount } = useGetCountOrdersQuery();
   const[orders,setOrders]=useState()
   useEffect(() => {
    
    setOrders(res_get_all?.data?.current_year_orders)
   }, [res_get_all])
   

   let data = [];
   let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
if (orders) {
    months.forEach(month => {
        let monthOrders = orders[month] || [];
    
        let pending = 0, complete = 0, cancelled = 0, total = 0;
    console.log(monthOrders)
        monthOrders.forEach(order => {
            switch(order.order_status) {
                case 'pending':
                    pending++;
                    break;
                case 'completed':
                    complete++;
                    break;
                case 'cancelled':
                    cancelled++;
                    break;
                    default:
                        console.log("object");
                        break;
            }
            total++;
        });
    
        data.push({
            name: month,
            pending: pending,
            complete: complete,
            cancelled: cancelled,
            total: total,
        });
    });
  
  }

  
  const maxValue = Math.max(...data.map(({pending, complete, cancelled}) => Math.max(pending, complete, cancelled)));
  const domain = [0, maxValue + 6]; // add extra 10 to the maximum value

  return (
    <>
{/*      
       <Grid item xs={12} sm={12} md={12} lg={12}>
            <SalesStatistics monthly={res_get_all?.data}  />
                </Grid> */}
      <Grid item xs={12} sm={4} lg={3}>
                    {/* <TasksCounterCard  count={res_get_all_order_count?.data?.orders?.total} title={"Total"}/> */}
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.total} title={"Total Orders"} IconName={'tasksIcon.svg'} bgColor={'#e6797a'} />
                </Grid>
          
            <Grid item xs={12} sm={4} lg={3}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.pending ?? 0} title={"Pending Orders"} IconName={'pendingIcon.svg'}  bgColor={'#ffc658'} />
                </Grid>
                {/* <Grid item xs={12} sm={4} lg={2.4}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.preOrder ?? 0} title={"Pre Orders"} IconName={'preOrderIcon.svg'}  bgColor={'#FF9C61'} />
                </Grid> */}
            {/* <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.inProcess} title={"InProcess Orders"} IconName={'inProcessIcon.svg'}  bgColor={'#00b7eb'} />
                </Grid> */}

                <Grid item xs={12} sm={4} lg={3}>
                 <FilesCounterCard count={res_get_all_order_count?.data?.orders?.completed ??0} title={"Complete Orders"} IconName={'completedIcon.svg'}   bgColor={'#23BCBA'}  />
                </Grid>
            <Grid item xs={12} sm={4} lg={3}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.cancelled ??0} title={"Cancelled Orders"} IconName={'cancelOrderIcon.svg'} bgColor={'#da393a'} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} md={12}>
                <Chart orders={data} domain={domain}/>
                </Grid>
            <br></br>
            <Grid item xs={12} sm={12} lg={12} md={12}>
                <LineChartOrder orders={data} domain={domain} />
            </Grid>
  </>

  )
}

export default OrderChart

