import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedHeroSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetHeroQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewHero = ({ selectId }) => {

    const selectedHero = useSelector(selectedHeroSelector);
    const [HeroId, setHeroId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingHero, error: errorHero } = useGetHeroQuery(HeroId);
    // const ViewHero = ({showId}) => {
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.hero_slider?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        res_get?.data?.hero_slider?.image && setSelectedFile(res_get?.data?.hero_slider?.image)
        setHeroId(selectedHero ? selectedHero?.id : selectId)
    }, [])
    const heroArray =
        [
            { label: 'Title', data: res_get?.data?.hero_slider?.title },

        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorHero ? <div>{JSON.stringify(errorHero)}</div>
                : isLoadingHero ? <div>Loading Single Hero.....</div>
                    : res_get?.data?.hero_slider ?
                        <DZGenericView obj={heroArray} imagePath={Config.digitalOceanLinkHeroSmallImg} image={selectedFile} checkImage={true} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewHero

