import React from 'react';
import { Grid } from "@mui/material";
import BitcoinPrice from "./components/BitcoinPrice";
import RipplePrice from "./components/RipplePrice";
import EthereumPrice from "./components/EthereumPrice";
import LitecoinPrice from "./components/LitecoinPrice";
import PropertiesList from "../../shared/widgets/PropertiesList";
import RecentActivities1 from "../../shared/widgets/RecentActivities1";
import MarkerClusterer from "../maps/MarkerClusterer";


import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from 'app/layouts/layouts';
import StoreTokenCheck from '../storetoken/StoreTokenCheck';

// import PortfolioBalance from "../../../shared/metrics/PortfolioBalance";
// import EarningExpenses from "../../../shared/metrics/EarningExpenses";
// import WordOfTheDay1 from "../../../shared/widgets/WordOfTheDay1";
// import EarnRewardCard from "../../../shared/widgets/EarnRewardCard";
// import CurrencyCalculator from "../../../shared/widgets/CurrencyCalculator";
// import LatestPosts from "../../../shared/widgets/LatestPosts";
// import MarketingCampaign from "../../../shared/widgets/MarketingCampaign";
// import CryptoNews from "../../../shared/widgets/CryptoNews";
import OrderChart from './../order_chart/OrderChart';
import LineChartOrder from './../../../@jumbo/dz-components/chart/LineChartOrder';
import SalesStatistics from './../../shared/metrics/SalesStatistics/SalesStatistics';
import { selectedUserLoginSelector } from 'app/rkt_query/SelectedStuffReducer';
import { useSelector } from 'react-redux';
import { useCustomStyle } from '@jumbo/hooks/useCustomStyle';


const Dashboard = () => {

    const { setActiveLayout } = useJumboApp();
 const selectedUserLogin = useSelector(selectedUserLoginSelector);
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
        if(selectedUserLogin){
            window.location.reload(false);
        }
       
    }, []);
    
  
    return (
        <>
            {/* <StoreTokenCheck /> */}
            <h1>Current Month Orders</h1><br></br>
            <Grid container spacing={3.75}>
           
            
                    <OrderChart />
             
                {/* <Grid item xs={12} sm={6} lg={3}>
                    <BitcoinPrice />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} lg={3}>
                    <RipplePrice />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <EthereumPrice />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <LitecoinPrice />
                </Grid> */}
                {/* <Grid item xs={12} lg={6}>
                <PortfolioBalance />
            </Grid>
            <Grid item xs={12} lg={6}>
                <EarningExpenses />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <WordOfTheDay1 />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <EarnRewardCard />
            </Grid>
            <Grid item xs={12} lg={4}>
                <CurrencyCalculator />
            </Grid>
            <Grid item xs={12} lg={6}>
                <LatestPosts scrollHeight={356} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <MarketingCampaign />
            </Grid>
            <Grid item xs={12}>
                <CryptoNews />
            </Grid> */}
                {/* <Grid item xs={12} lg={8}>
                    <PropertiesList />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <RecentActivities1 scrollHeight={556} />
                </Grid>
                <Grid item xs={12}>
                    <MarkerClusterer isExample={false} />
                </Grid> */}
            </Grid>
        </>
    );
};

export default Dashboard;
