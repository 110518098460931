import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {
  useGetAllHerosQuery, BLUE_PRINTS, useGetHeroQuery, useDeleteHeroMutation,
  // useGetAllCompaniesQuery
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Hero from './AddUpdateHero';
import { setSelectedHero } from 'app/rkt_query/SelectedStuffReducer';
import ViewHero from './ViewHero';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';

// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import Ordering from '@jumbo/dz-components/drag-drop-ordering/Ordering';



const HeroList = () => {

  const location = useLocation();
  const navigate = useNavigate();


  // console.log("In Hero company State ", location?.state?.company);
  console.log("In Hero Relational Objext", location?.state);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteHero, responseDelete] = useDeleteHeroMutation();
  const { data: res_get_all, isLoading: isLoadingHeros, error: errorHeros } = useGetAllHerosQuery();
  // const { data: res_get_all_companies, isLoading: isLoadingCompanies, error: errorCompanies } = useGetAllCompaniesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.hero
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedHero(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedHero(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedHero(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedHero(row)); }
  bluePrint.handleDelete = (id) => { deleteHero(id); }








  // const handleBack = () => {

  //   //console.log("in navigation",   res_get?.data?.learning_journey?.topic?.module?.hero)
  //   navigate("/companies", {

  //   });
  // }
  //const check = modules.map((item) => item?.modules[0] ? item?.modules : null)
const size="xl";
  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
    
        <JumboDemoCard
          title={"Hero"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="Hero"
              relationalObject={location?.state?.relationalObject}
              // listData={res_get_all_companies?.data?.companies}
              companyName={location?.state?.company}
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.hero_sliders}
              bluePrint={bluePrint}
              ViewModel={() => <ViewHero />}
              ModalContent={() => <Hero onClose={hideDialog} />}
              // heirarchyId={location?.state?.obj?.id}
              // RelationalModalContent={() => <Module onClose={hideDialog} />}
              // relationalTitle="Module"
              // ddName="Companies"
              showAddButton={false}
            // addAndViewTitle="Assign Users"
            />
            {/* <DataTable btnTitleAdd="hero" data={res_get_all?.data?.heros} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowHero/> : <Hero onClose={hideDialog} />} /> */}
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default HeroList