import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab, Box } from "@mui/material";
import {
  selectedUserSelector,
  setSelectedUser,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import DZDefaultImg from "app/DZcomponents/DZDefaultImg";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateUser = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectedUserSelector);
  const {
    data: res_get,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useGetUserQuery(selectedUser?.id);
  const { fnShowSnackBar } = useSnackBarManager();
  const [updateUser, responseUpdate] = useUpdateUserMutation();
  const [addUser, responseAdd] = useAddUserMutation();
  const bluePrint = BLUE_PRINTS.user;

  const [disable, setDisabled] = useState([true]);

  const [userState, setUserState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const [count, setCount] = useState(true);
  const [open, setOpen] = useState(false);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.user?.image);
  const roles = ["user", "deliver"];
  const addUpdateUserData = async (action) => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("id", res_get?.data?.user?.id);
    formData.append("name", userState.name);
    formData.append("email", userState.email);
    formData.append("password", userState.password);
    formData.append("mobile", userState.mobile);
    formData.append("role", userState.role);
    setOpen(true);
    // formData.append('company_id', userState?.company_id);
    // formData.append('team_id', userState?.team_id);
    // formData.append('order_type', userState?.order_type);
    // formData.append('date', userState?.date);
    // formData.append('description', JSON.stringify(userState.description));

    // return false;
    const res =
      action === "add" ? await addUser(formData) : await updateUser(formData);
    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar("Something went wrong", true);
    }
  };
  if (responseAdd?.error?.data?.errors && count) {
    setCount(false);
    setError(responseAdd?.error?.data?.errors);
  }

  const countZero = () => {
    setCount(true);
  };
  console.log(responseUpdate?.error?.data?.errors);
  if (responseAdd?.error?.data?.errors && count) {
    setCount(false);
    setError(responseAdd?.error?.data?.errors);
  }

  useEffect(() => {
    res_get?.data?.user?.image && setSelectedFile(res_get?.data?.user?.image);

    // setCompanyId(res_get1?.data?.company?.id)
    setUserState(selectedUser ? res_get?.data?.user : bluePrint);
    // setInputFields(modules)
    // setUserState(...courseState?.company_id && )
    setDisabled(selectedUser ? false : true);

    // if (res_get1?.data?.company?.id) {
    //   //dispatch(setSelectedUser(null));
    //   console.log("Checking")
    // }
  }, [res_get]);

  console.log("update response", userState);
  // const companies = res_get_all?.data?.companies?.map((company) => (company))
  // const callCompanyTeams = () => {
  //   setCompanyId(courseState?.company_id)
  // }
  // console.log(companies, "companies")
  // console.log('comp', companies)
  // console.log('transformed result :: ', res_get1?.data?.company?.name)
  // console.log('Disable :: ', disable)
  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // const handleFormChange = (index, event) => {
  //   console.log('event: ', event.target.value);
  //   setInputFields(inputFields?.map((eng, i) => i === index ? {
  //     ...eng,
  //     [event.target.name]: (
  //       event.target.value)
  //   } : eng))
  // const values = [...inputFields];
  // values[index].value = event.target.value;
  // setInputFields(values);

  //console.log('event target value: ', event.target.value);
  //document.getElementsByName("reply_exercise_id")''
  // setInputFields(inputFields.map((eng, i) => i === index ? {
  //   ...eng,
  //   [event.target.name]: (
  //     event.target.value
  //       : { ...eng?.[event.target.name], [selectedLanguage]: event.target.value }
  // )
  // } : eng));

  // }
  // console.warn(inputFields);
  // const company_teams = res_get_all_team?.data?.teams?.map((team) => (team));

  return (
    <div>
      <SnackAndBackDrop
        showBackDrop={showBackDrop}
        responseAdd={responseAdd}
        responseUpdate={responseUpdate}
        setOpen={setOpen}
        open={open}
      />

      {userState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* image start */}
          <DZDefaultImg src={imagePreview} selectedFile={selectedFile} />

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>
          {/* <DZDropdown error={error?.company_id} placeholder={'Select Company'} value={courseState?.company_id} data={companies}
            onChange={(e) => { setUserState(x => ({ ...x, company_id: e.target.value, })); callCompanyTeams(); }} /> */}

          <DZSimpleDropDown
            value={userState?.role||""}
            data={roles}
            name="role"
            placeholder="Select Role"
            onChange={(e) => {
              setUserState((x) => ({ ...x, role: e.target.value }));
            }}
          />

          <DZTextValidation
            error={error?.name}
            label="Name"
            variant="outlined"
            value={userState?.name}
            onChange={(e) => {
              setUserState((x) => ({ ...x, name: e.target.value }));
            }}
            placeholder="Name"
          />

          <DZTextValidation
            error={error?.email}
            label="Email"
            variant="outlined"
            value={userState?.email}
            onChange={(e) => {
              setUserState((x) => ({ ...x, email: e.target.value }));
            }}
            placeholder="Email"
          />
          <DZTextValidation
            error={error?.mobile}
            label="Mobile"
            variant="outlined"
            value={userState?.mobile}
            onChange={(e) => {
              setUserState((x) => ({ ...x, mobile: e.target.value }));
            }}
            placeholder="Mobile"
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={userState?.password}
            {...(error?.password && {
              error: true,
              helperText: error?.password,
            })}
            onChange={(e) => {
              setUserState((x) => ({ ...x, password: e.target.value }));
            }}
            placeholder="Password"
          />

          {/* <TextField label="Start Date" type="date" variant="outlined" value={courseState?.date}
            onChange={(e) => { setUserState(x => ({ ...x, date: e.target.value })) }}

          /> */}
          {/* {
            (courseState?.order_type === "order_sequence" && inputFields) ? <form>
              <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                <CardContent>
                  {inputFields?.map((input, index) => {
                    return (

                      <div key={index} >

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DzNoBackgroundTranslatableModule children={input?.name} />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>

                            <input type="hidden" name='id' value={input?.id} onChange={event => handleFormChange(index, event)} />

                            <TextField fullWidth label="Start Time" type='date' name='start_datetime' variant="outlined" noOfRows={1} value={input.start_datetime}
                              onChange={event => handleFormChange(index, event)}
                            />
                          </Div>
                        </Grid>


                      </div>

                    )
                  })}
                </CardContent>
              </Card>
            </form> : ''

          } */}
          {/* <DZTextField label="Description" variant="outlined" noOfRows={3} value={courseState?.description[selectedLanguage]}
            onChange={(e) => { setUserState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
          /> */}
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedUser(null));
              }}
            >
              Cancel
            </Button>
            {selectedUser?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateUserData();
                  setShowBackDrop(true);
                  countZero();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateUserData("add");
                  setShowBackDrop(true);
                  countZero();
                  dispatch(setSelectedUser(null));
                }}
              >
                Add
              </Button>
            )}
            {/* dispatch(setSelectedUser(null)); */}
          </div>
          {/* <div style={{ width: '10%' }}>{JSON.stringify(courseState)}</div> */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
      {/* {!selectedUser &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}
    </div>
  );
};

export default AddUpdateUser;
