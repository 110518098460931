import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {
  useGetAllSizesQuery, BLUE_PRINTS, useGetSizeQuery, useDeleteSizeMutation,
  // useGetAllCompaniesQuery
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Size from './AddUpdateSize';
import { setSelectedSize } from 'app/rkt_query/SelectedStuffReducer';
import ViewSize from './ViewSize';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';

// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';



const SizeList = () => {

  const location = useLocation();
  const navigate = useNavigate();



  console.log("In Size Relational Objext", location?.state);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteSize, responseDelete] = useDeleteSizeMutation();
  const { data: res_get_all, isLoading: isLoadingSizes, error: errorSizes } = useGetAllSizesQuery();

  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.size
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedSize(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedSize(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedSize(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedSize(row)); }
  bluePrint.handleDelete = (id) => { deleteSize(id); }

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"Size"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="Size"
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.sizes}
              bluePrint={bluePrint}
              ViewModel={() => <ViewSize />}
              ModalContent={() => <Size onClose={hideDialog} />}
        
              showAddButton={false}
           
            />
           
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default SizeList